
const config = {
  //serverDomain: 'https://testapi.foglaljpalyat.hu:9006', // test server
  //serverDomain: 'https://api.foglaljpalyat.hu:39006', // demo server
  // serverDomain: 'https://api.foglaljpalyat.hu:29006', // live server
   serverDomain: 'https://api.foglalas.coimbraits.hu:9007', // foglalas test server
  // serverDomain: 'https://api.foglalas.coimbraits.hu:29007', // foglalas live server
  tokenCookieName: 'token'
}

// current client version
config.CURRENT_VERSION = 24
config.DEFAULT_LOGO_URL = 'https://foglaljpalyat.hu/img/foglaljpalyat_logo.png'

export default config
