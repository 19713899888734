<template>
  <div class="scrollable-modal">
    <modal
      name="userEditModal"
      width="800px"
      height="600px"
      @before-open="beforeOpen"
      @closed="modalClosed"
    >
      <div class="modal-content">
        <h5 class="text-center bottom-border-separator">
          Felhasználó Módosítása
        </h5>
        <div class="row">
          <div class="medium-6 columns">
            <label>Név*
              <input
                id="userName"
                v-model="user.name"
                type="text"
                :class="{'is-invalid-input' : showError.name}"
              >
              <span
                v-if="showError.name"
                class="form-error is-visible"
              >A név kitöltése kötelező</span>
            </label>
          </div>
          <div class="medium-6 columns">
            <label>Email
              <input
                v-model="user.email"
                type="email"
                readonly
              >
            </label>
            <!--
            <label>Email*
              <input id="userEmail"
                     type="email"
                     :class="{'is-invalid-input' : showError.email}"
                     v-model="user.email">
              <span v-if="showError.email" class="form-error is-visible">Email kitöltése kötelező, helyes formátum: minta@mail.hu</span>
            </label>
            -->
          </div>
          <div class="medium-6 columns">
            <!--
            <label>Telefonszám
              <input id="userPhone"
                     type="number"
                     :class="{'is-invalid-input' : showError.phone}"
                     @blur="blurPhone"
                     v-model="user.phone">
              <span v-if="showError.phone" class="form-error is-visible">Minta: 701112233</span>
            </label>
            -->
            <label>
              Telefonszám
            </label>
            <vue-tel-input
              v-model="user.phone"
              default-country="HU"
              placeholder="Telefonszám megadása"
              @onInput="phoneUpdate"
              @onValidate="onPhoneValidate"
              @onBlur="blurPhone"
            />
            <span
              v-if="showError.phone"
              class="form-error is-visible"
            >Csak érvényes telefonszám adható meg</span>
          </div>
          <div class="medium-6 columns">
            <label>Vendégtípus*
              <select
                id="userType"
                v-model="user.guestType"
              >
                <option
                  v-for="gType in guestTypes"
                  :value="gType.name"
                >{{ getGuestTypeName(gType.name) }}</option>
              </select>
            </label>
          </div>
          <div class="medium-12 columns">
            <label>Megjegyzés
              <input
                id="userComment"
                v-model="user.comment"
                type="text"
              >
            </label>
          </div>
          <div class="medium-4 columns">
            <label for="userActive">Aktív</label>
            <div class="switch large">
              <input
                id="userActive"
                v-model="user.isActive"
                class="switch-input"
                type="checkbox"
                :true-value="true"
              >
              <label
                class="switch-paddle"
                for="userActive"
              >
                <span
                  class="switch-active"
                  aria-hidden="true"
                >I</span>
                <span
                  class="switch-inactive"
                  aria-hidden="true"
                >N</span>
              </label>
            </div>
          </div>
          <div class="medium-4 columns">
            <label for="userCanFreeResign">Ingyenes lemondhat</label>
            <div class="switch large">
              <input
                id="userCanFreeResign"
                v-model="user.canFreeResign"
                class="switch-input"
                type="checkbox"
                :true-value="true"
              >
              <label
                class="switch-paddle"
                for="userCanFreeResign"
              >
                <span
                  class="switch-active"
                  aria-hidden="true"
                >I</span>
                <span
                  class="switch-inactive"
                  aria-hidden="true"
                >N</span>
              </label>
            </div>
          </div>
          <div class="medium-4 columns">
            <label for="userIsExclusive">Exclusive Tag</label>
            <div class="switch large">
              <input
                id="userIsExclusive"
                v-model="user.isExclusive"
                class="switch-input"
                type="checkbox"
                :true-value="true"
              >
              <label
                class="switch-paddle"
                for="userIsExclusive"
              >
                <span
                  class="switch-active"
                  aria-hidden="true"
                >I</span>
                <span
                  class="switch-inactive"
                  aria-hidden="true"
                >N</span>
              </label>
            </div>
          </div>
          <div class="medium-12 columns">
            <div
              v-if="currentUser.isConfirmed"
              class="callout success"
            >
              <p>A felhasználó megerősítette az email-címét.</p>
            </div>
            <div
              v-else
              class="callout warning"
            >
              <p>A felhasználó email-címe még nincs megerősítve!</p>
            </div>
            <!--
            <div>Vendég email-cím konfirmálva: {{ currentUser.isConfirmed === 1 }}</div>
            -->
          </div>
          <div class="medium-12 columns">
            <button
              class="button warning"
              @click="changeEmailAddress()"
            >
              Email csere
            </button>
            <button
              class="button warning"
              @click="emailConfirmationResend()"
            >
              Megerősítő email újraküldése
            </button>
            <button
              class="button warning"
              @click="newPassword()"
            >
              Új jelszó küldése
            </button>
            <button
              class="button alert"
              @click="deleteUser()"
            >
              Felhasználó törlése
            </button>
            <button
              class="button primary"
              @click="openGlobalComments"
            >
              Globális megjegyzések
            </button>
          </div>
          <hr>
          <div class="medium-12 columns text-right top-border-separator">
            <button
              class="button"
              @click="saveChanges()"
            >
              Mentés
            </button>
            <button
              class="button secondary"
              @click="closeUserEditModal()"
            >
              Mégse
            </button>
          </div>
        </div>
      </div>
      <button
        class="close-button"
        data-close
        aria-label="Close modal"
        type="button"
        @click="closeUserEditModal()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </modal>
    <user-global-comment-modal />
    <change-email-modal />
  </div>
</template>

<script>
import { getCookie } from '../../../../../../processors/cookie'
import errorHandle from '@/processors/errorHandle'
import notify from '@/processors/notify'
import UserGlobalCommentModal from './UserGlobalCommentModal'
import ChangeEmailModal from './ChangeEmailModal'
import 'vue-tel-input/dist/vue-tel-input.css'
import VueTelInput from 'vue-tel-input'

export default {
  components: {
    UserGlobalCommentModal,
    ChangeEmailModal,
    VueTelInput
  },
  data () {
    return {
      user: {
        name: null,
        phone: null,
        email: null,
        comment: null,
        guestType: null,
        isActive: null,
        canFreeResign: null,
        isExclusive: null
        // googleCalendar: null
      },
      isPhoneTouched: false,
      isPhoneValid: false
    }
  },
  computed: {
    guestTypeName () {
      return this.$store.state.guestTypes
    },
    currentUser () {
      return this.$store.state.guest.current
    },
    guestTypes () {
      return this.$store.state.guest.guestTypes
    },
    clubs () {
      return [
        { ID: 1, name: 'Club A' },
        { ID: 1, name: 'Club B' },
        { ID: 1, name: 'Club C' }
      ]
    },
    isEmailValid () {
      const email = this.user.email
      // eslint-disable-next-line
      let regExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return regExp.test(email)
    },
    /* isPhoneValid () {
      let phone = this.user.phone
      let isValid = false
      if (phone) {
        if (!isNaN(phone)) {
          if (phone.toString().length > 8) {
            isValid = true
          }
        }
      }
      if (phone === '') {
        isValid = true
      }
      return isValid
    }, */
    isNameValid () {
      let isValid = false
      const userName = this.user.name
      if (userName) {
        if (userName.length > 0) {
          isValid = true
        }
      }
      return isValid
    },
    showError () {
      return {
        name: !this.isNameValid,
        email: !this.isEmailValid,
        phone: this.isPhoneTouched ? this.user.phone || this.user.phone !== '' ? !this.isPhoneValid : false : false
      }
    }
  },
  methods: {
    getGuestTypeName (guestTypeKey) {
      let guestTypeName = ''
      const guestTypes = this.$store.state.availableGuestTypes
      if (guestTypeKey) {
        for (let i = 0; i < guestTypes.length; i++) {
          const guestType = guestTypes[i]
          if (guestType.key === guestTypeKey) {
            guestTypeName = guestType.name
            break
          }
        }
      }
      return guestTypeName
    },
    beforeOpen () {
      const user = this.$store.state.guest.current
      this.user.name = user.name
      this.user.phone = user.phone
      this.user.email = user.email
      this.user.comment = user.comment
      this.user.guestType = user.guestType
      this.user.isActive = user.isActive === 1 || user.isActive === true
      this.user.canFreeResign = user.canFreeResign === 1 || user.canFreeResign === true
      this.user.isExclusive = user.isExclusive === 1 || user.isExclusive === true
      // this.user.googleCalendar = user.googleCalendar === 1 || user.googleCalendar === true

      console.log('$$$$$$$$$')
      console.log(this.user)
    },
    modalClosed () {
      this.user = {
        name: null,
        phone: null,
        email: null,
        comment: null,
        guestType: null,
        isActive: null,
        canFreeResign: null,
        isExclusive: null
        // googleCalendar: null
      }
      this.isPhoneTouched = false
    },
    saveChanges () {
      if (/* this.isEmailValid && */(this.isPhoneValid || this.user.phone === '') && this.isNameValid) {
        const userData = {
          guestID: this.$store.state.guest.current.ID,
          name: this.user.name,
          phone: this.user.phone,
          // email: this.user.email,
          comment: this.user.comment,
          guestType: this.user.guestType,
          canFreeResign: document.getElementById('userCanFreeResign').checked,
          // googleCalendar: document.getElementById('userGoogleCalendar').checked,
          isActive: document.getElementById('userActive').checked,
          isExclusive: document.getElementById('userIsExclusive').checked,
          sendEmail: null // TODO send email ???
        }

        console.log('--- save user data: ')
        console.log(userData)

        this.$http({
          url: this.$store.state.url.updateGuest,
          method: 'PUT',
          params: { userID: getCookie('userID'), token: getCookie('token') },
          body: JSON.stringify({ data: userData })
        }).then((response) => {
          console.log('--- success modification')
          notify.success(response.body.msg)
        }, error => {
          errorHandle.httpError(error)
        }).finally(() => {
          this.$modal.hide('userEditModal')
        })
      } else {
        notify.warning('Minden mezőt helyesen kell kitölteni')
      }
    },
    closeUserEditModal () {
      this.$modal.hide('userEditModal')
    },
    emailConfirmationResend () {
      this.$http({
        url: this.$store.state.url.emailConfirmationResend,
        method: 'GET',
        params: { userID: getCookie('userID'), token: getCookie('token'), guestID: this.$store.state.guest.current.ID }
      }).then(response => {
        console.log('--- email confirmation resent')
        notify.success(response.body.msg)
      }, error => {
        errorHandle.httpError(error)
      }).finally(() => {
        // self.$modal.hide('userEditModal')
      })
    },
    newPassword () {
      this.$http({
        url: this.$store.state.url.newPwdSend,
        method: 'POST',
        params: { userID: getCookie('userID'), token: getCookie('token'), email: this.$store.state.guest.current.email }
      }).then(response => {
        console.log('--- new pwd sent')
        notify.success(response.body.msg)
      }, error => {
        errorHandle.httpError(error)
      }).finally(() => {
        // self.$modal.hide('userEditModal')
      })
    },
    deleteUser () {
      const self = this
      notify.confirmation('Biztosan törli a felhasználót?')
        .then(function success () {
          // confirm
          const currentGuestID = self.$store.state.guest.current.ID
          const currentGuestName = self.$store.state.guest.current.name
          const currentGuestEmail = self.$store.state.guest.current.email
          self.$http({
            url: self.$store.state.url.getGuestByID,
            method: 'DELETE',
            params: { userID: getCookie('userID'), token: getCookie('token') },
            body: JSON.stringify({ data: { guestID: currentGuestID, name: currentGuestName, email: currentGuestEmail } })
          }).then(response => {
            console.log('--- user is deleted')
            notify.success(response.body.msg)
          }, error => {
            errorHandle.httpError(error)
          }).finally(() => {
            self.$modal.hide('userEditModal')
          })
        }, function () {
          // cancelled
          console.log('--- user delete cancelled')
        })

      /*
      notify.confirmation('user törlése').then(resp => {
        console.log('it works baby')
        console.log(resp)
      }, err => {
        console.log('error works too baby')
        console.log(err)
      })
      */
    },
    blurPhone () {
      this.isPhoneTouched = true
    },
    openGlobalComments () {
      this.$modal.show('userGlobalCommentModal')
    },
    changeEmailAddress () {
      this.$modal.show('changeEmailModal')
    },
    phoneUpdate ({ number, isValid, country }) {
      /*
      console.log('--- phoneUpdateTest:')
      console.log(number, isValid, country)
      */
    },
    onPhoneValidate ({ number, isValid, country }) {
      // alert('validate')
      this.isPhoneValid = isValid
    }
  }
}
// TODO: clubs
</script>

<style scoped>
  .callout {
    padding: 1rem;
  }
</style>
