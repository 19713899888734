// import VueInstance from '../../main'
import url from '../../api/url'
import { getCookie } from '../../processors/cookie'
import errorHandle from '@/processors/errorHandle'
import Vue from 'vue'
// import moment from '../index'

const state = {
  isSelected: false,
  current: {
    ID: null,
    name: null,
    email: null,
    guestType: null,
    comment: null,
    balance: null,
    isActive: null,
    phone: null,
    isConfirmed: null,
    canFreeResign: null,
    sendEmail: null,
    googleCalendar: null,
    isClubMember: null,
    isExclusive: null,
    clubs: [],
    passes: [],
    activeReservations: []
  },
  all: [],
  guestTypes: [],
  cashFlow: []
}

const mutations = {
  FETCH_GUEST (state, data) {
    state.isSelected = true
    state.current = data
  },
  UNSELECT_GUEST (state) {
    state.isSelected = false
    state.current = {
      ID: null,
      name: null,
      email: null,
      guestType: null,
      comment: null,
      balance: null,
      isActive: null,
      phone: null,
      isConfirmed: null,
      canFreeResign: null,
      sendEmail: null,
      googleCalendar: null,
      isClubMember: null,
      isExclusive: null,
      clubs: [],
      passes: [],
      activeReservations: []
    }
  },
  FETCH_ALL_GUEST (state, data) {
    state.all = data.guests
    state.guestTypes = data.guestTypes
  },
  FETCH_GUEST_ALL_PASS (state, data) {
    Vue.set(state.current, 'allPass', data)
  },
  FETCH_GUEST_ALL_RESERVATION (state, data) {
    Vue.set(state.current, 'allReservation', data)
  },
  FETCH_CASH_FLOW (state, data) {
    Vue.set(state, 'cashFlow', data)
  },
  CURRENT_GUEST_DATA_UPDATE (state, modifyGuest) {
    const current = state.current
    const updatedCurrent = {
      ID: current.ID,
      name: modifyGuest.name,
      email: current.email,
      guestType: modifyGuest.guestType,
      comment: modifyGuest.comment,
      balance: current.balance,
      isActive: modifyGuest.isActive,
      phone: modifyGuest.phone,
      isConfirmed: current.isConfirmed,
      canFreeResign: modifyGuest.canFreeResign,
      sendEmail: modifyGuest.sendEmail,
      googleCalendar: modifyGuest.googleCalendar,
      isExclusive: modifyGuest.isExclusive,
      isClubMember: null,
      clubs: [],
      passes: current.passes,
      activeReservations: current.activeReservations
    }
    state.current = updatedCurrent
  },
  ALL_GUEST_DATA_ONE_GUEST_UPDATE (state, data) {
    const modifyGuest = data.modifyGuest
    const guestIndex = data.guestIndex
    const selectedGuest = state.all[guestIndex]
    const updatedGuest = {
      ID: selectedGuest.ID,
      balance: selectedGuest.balance,
      isConfirmed: selectedGuest.isConfirmed,
      canFreeResign: modifyGuest.canFreeResign,
      comment: modifyGuest.comment,
      email: state.all[guestIndex].email,
      googleCalendar: modifyGuest.googleCalendar,
      guestType: modifyGuest.guestType,
      isActive: modifyGuest.isActive,
      name: modifyGuest.name,
      phone: modifyGuest.phone,
      sendEmail: modifyGuest.sendEmail,
      isExclusive: modifyGuest.isExclusive
    }
    state.all[guestIndex] = updatedGuest
  },
  CONFIRM_GUEST (state, data) {
    const guestID = data.guestID
    const guestIndex = data.guestIndex
    const current = state.current
    if (Number(guestID) === Number(current.ID)) {
      Vue.set(state.current, 'isConfirmed', true)
    }
    Vue.set(state.all[guestIndex], 'isConfirmed', true)
  },
  ADD_NEW_GUEST (state, data) {
    state.all.push(data.newGuest)
  },
  REMOVE_GUEST (state, data) {
    const guestIndex = data.guestIndex
    state.all.splice(guestIndex, 1)
  },
  GUEST_BALANCE_CHANGE (state, data) {
    const guestID = data.guestID
    const guestIndex = data.guestIndex
    const newBalance = data.newBalance
    const current = state.current
    if (Number(current.ID) === Number(guestID)) {
      Vue.set(state.current, 'balance', newBalance)
    }
    Vue.set(state.all[guestIndex], 'balance', newBalance)
  },
  UPDATE_CURRENT_GUEST_ACTIVE_RESERVATIONS (state, data) {
    Vue.set(state.current, 'activeReservations', data.activeReservations)
  },
  UPDATE_CURRENT_GUEST_PASSES (state, passes) {
    Vue.set(state.current, 'passes', passes)
  },
  UPDATE_CURRENT_GUEST_EMAIL (state, newEmail) {
    if (state.current && state.current.hasOwnProperty('email') && state.current.email) {
      Vue.set(state.current, 'email', newEmail)
    }
  },
  UPDATE_GUEST_EMAIL_IN_ALL_GUEST_LIST (state, data) {
    const newEmail = data.newEmail
    const userIndex = data.userIndex
    if (state.all && state.all[userIndex] && state.all[userIndex].hasOwnProperty('email')) {
      Vue.set(state.all[userIndex], 'email', newEmail)
    }
  },
  ACTIVATE_CURRENT_GUEST (state) {
    Vue.set(state.current, 'isActive', true)
  },
  ACTIVATE_GUEST_IN_ALL_GUEST (state, guestIndex) {
    Vue.set(state.all[guestIndex], 'isActive', true)
  },
  UPDATE_GUEST_PHONE_NUMBER (state, data) {
    const email = data.email
    const newPhoneNumber = data.newPhoneNumber
    const currentGuestEmail = state.current.email
    const allGuest = state.all

    if (currentGuestEmail === email) {
      Vue.set(state.current, 'phone', newPhoneNumber)
    }

    for (const i in allGuest) {
      if (allGuest[i].email === email) {
        Vue.set(state.all[i], 'phone', newPhoneNumber)
        break
      }
    }
  }
}

const actions = {
  fetchGuestByID ({ commit }, data) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: url.getGuestByID,
        method: 'GET',
        params: { userID: getCookie('userID'), token: getCookie('token'), reqUserID: data.reqUserID }
      })
        .then((response) => {
          const guest = response.body
          console.log('***********************************')
          console.log('--- Fetched new user : ')
          console.log(guest)
          console.log('***********************************')
          commit('FETCH_GUEST', response.body)
          resolve()
        })
        .catch(error => {
          errorHandle.httpError(error)
        })
    })
  },
  fetchCashFlow ({ commit, state }) {
    const guestID = state.current.ID
    return new Promise((resolve, reject) => {
      Vue.http({
        url: url.getCashFlow,
        method: 'GET',
        params: { userID: getCookie('userID'), token: getCookie('token'), guestID: guestID }
      }).then((response) => {
        console.log('***********************************')
        console.log('--- fetchCashFlow success: ')
        console.log(response.body)
        console.log('***********************************')
        commit('FETCH_CASH_FLOW', response.body)
        resolve()
      }, error => {
        errorHandle.httpError(error)
      })
    })
  },
  unselectGuest ({ commit }) {
    commit('UNSELECT_GUEST')
  },
  fetchAllGuest ({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: url.getAllGuest,
        method: 'GET',
        params: { userID: getCookie('userID'), token: getCookie('token') }
      })
        .then((response) => {
          console.log('***********************************')
          console.log('--- Fetched all guest : ')
          console.log(response.body)
          console.log('***********************************')
          commit('FETCH_ALL_GUEST', response.body)
          resolve()
        })
        .catch(error => {
          errorHandle.httpError(error)
        })
    })
  },
  fetchGuestAllPass ({ commit }, data) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: url.getGuestAllPass,
        method: 'GET',
        params: { userID: getCookie('userID'), token: getCookie('token'), reqUserID: data.reqUserID }
      })
        .then((response) => {
          console.log('***********************************')
          console.log('--- Fetched guest all pass: ')
          console.log(response.body)
          console.log('***********************************')
          commit('FETCH_GUEST_ALL_PASS', response.body)
          resolve()
        })
        .catch(error => {
          errorHandle.httpError(error)
        })
    })
  },
  fetchGuestAllReservation ({ commit }, data) {
    console.log(data)
    console.log(data.reqUserID)
    return new Promise((resolve, reject) => {
      Vue.http({
        url: url.getGuestAllReservation,
        method: 'GET',
        params: { userID: getCookie('userID'), token: getCookie('token'), reqUserID: data.reqUserID }
      })
        .then((response) => {
          console.log('***********************************')
          console.log('--- Fetched guest all reservation: ')
          console.log(response.body)
          console.log('***********************************')
          commit('FETCH_GUEST_ALL_RESERVATION', response.body)
          resolve()
        })
        .catch(error => {
          errorHandle.httpError(error)
        })
    })
  },
  socket_guestDataModified ({ commit, state }, data) {
    console.log('socket_guestDataModified')
    const modifyGuest = data.guest
    let curGuest, allGuests, guestIndex
    console.log(modifyGuest)
    if (state.hasOwnProperty('current')) {
      // current quest update
      curGuest = state.current
      if (Number(modifyGuest.guestID) === Number(curGuest.ID)) {
        commit('CURRENT_GUEST_DATA_UPDATE', modifyGuest)
      }
    }
    if (state.hasOwnProperty('all')) {
      // all guest update
      allGuests = state.all
      guestIndex = getGuestIndexFromArray(modifyGuest.guestID, allGuests)
      if (guestIndex) {
        commit('ALL_GUEST_DATA_ONE_GUEST_UPDATE', { modifyGuest: modifyGuest, guestIndex: guestIndex })
      }
    }
  },
  socket_guestConfirmed ({ commit, state }, data) {
    console.log('socket_guestConfirmed')
    const guestID = data.guestID
    const guestIndex = getGuestIndexFromArray(guestID, state.all)
    if (guestIndex !== false) {
      commit('CONFIRM_GUEST', { guestID: guestID, guestIndex: guestIndex })
    }
  },
  socket_addNewGuest ({ commit }, data) {
    console.log('socket_addNewGuest')
    console.log(data)
    commit('ADD_NEW_GUEST', { newGuest: data.newGuest })
  },
  socket_removeGuest ({ commit, state }, data) {
    console.log('socket_removeGuest')
    const guestID = data.guestID
    const allGuests = state.all
    const guestIndex = getGuestIndexFromArray(guestID, allGuests)
    const current = state.current
    if (Number(current.ID) === Number(guestID)) {
      console.log('unselect')
      commit('UNSELECT_GUEST')
    }
    console.log(guestIndex)
    if (guestIndex !== false) {
      console.log('remove guest')
      commit('REMOVE_GUEST', { guestIndex: guestIndex })
    }
  },
  socket_balanceChange ({ commit, state }, data) {
    console.log('socket_balanceChange')
    const guestID = data.guestID
    const newBalance = data.newBalance
    const guestIndex = getGuestIndexFromArray(guestID, state.all)
    if (guestIndex !== false) {
      commit('GUEST_BALANCE_CHANGE', { guestID: guestID, newBalance: newBalance, guestIndex: guestIndex })
    }
  },
  socket_updateGuestActiveReservations ({ commit, state }, data) {
    console.log('socket_updateGuestActiveReservations')
    const guestID = data.guestID
    const activeReservations = data.activeReservations
    const current = state.current
    if (Number(current.ID) === Number(guestID)) {
      commit('UPDATE_CURRENT_GUEST_ACTIVE_RESERVATIONS', { activeReservations: activeReservations })
    }
  },
  socket_updateGuestPasses ({ commit, state }, data) {
    console.log('socket_updateGuestPasses')
    const guestID = data.guestID
    const current = state.current
    if (Number(current.ID) === Number(guestID)) {
      commit('UPDATE_CURRENT_GUEST_PASSES', data.passes)
    }
  },
  socket_updateGuestEmail ({ commit, state }, data) {
    console.log('socket_updateGuestEmail')
    const oldEmail = data.oldEmail
    const newEmail = data.newEmail
    const currentGuest = state.current
    const allGuest = state.all
    if (currentGuest && currentGuest.hasOwnProperty('email') && currentGuest.email) {
      if (currentGuest.email === oldEmail) {
        commit('UPDATE_CURRENT_GUEST_EMAIL', newEmail)
      }
    }
    if (allGuest) {
      for (const i in allGuest) {
        const guest = allGuest[i]
        if (guest && guest.hasOwnProperty('email')) {
          if (guest.email === oldEmail) {
            commit('UPDATE_GUEST_EMAIL_IN_ALL_GUEST_LIST', {
              newEmail: newEmail,
              userIndex: i
            })
          }
        }
      }
    }
  },
  socket_guestActivated ({ commit, state }, data) {
    console.log('socket_guestActivated')
    const guestID = data.guestID
    const current = state.current
    let allGuests
    if (Number(current.ID) === Number(guestID)) {
      commit('ACTIVATE_CURRENT_GUEST', data.passes)
    }
    if (state.hasOwnProperty('all')) {
      allGuests = state.all
      const guestIndex = getGuestIndexFromArray(guestID, allGuests)
      if (guestIndex) {
        commit('ACTIVATE_GUEST_IN_ALL_GUEST', guestIndex)
      }
    }
  },
  socket_updateGuestPhoneNumber ({ commit }, data) {
    console.log('socket_updateGuestPhoneNumber')
    if (data.hasOwnProperty('email') && data.hasOwnProperty('newPhoneNumber')) {
      commit('UPDATE_GUEST_PHONE_NUMBER', data)
    }
  }
}

const getters = {
}

const guest = {
  state,
  mutations,
  actions,
  getters
}

export default guest

function getGuestIndexFromArray (guestID, guests) {
  let index = false
  for (let i = 0; i < guests.length; i++) {
    if (Number(guestID) === Number(guests[i].ID)) {
      index = i
      break
    }
  }
  return index
}
/* eslint-disable */
function isGuestEmailSet (guest) {
}
