import { render, staticRenderFns } from "./Statistic.vue?vue&type=template&id=45144364&scoped=true&"
import script from "./Statistic.vue?vue&type=script&lang=js&"
export * from "./Statistic.vue?vue&type=script&lang=js&"
import style0 from "./Statistic.vue?vue&type=style&index=0&id=45144364&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45144364",
  null
  
)

export default component.exports