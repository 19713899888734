<template>
  <div class="row">
    <div class="medium-12 columns">
      <div class="date-container">
        <label for="closeDate">Melyik nap</label>
        <date-picker
          id="closeDate"
          v-model="date"
          format="yyyy. MM. dd."
          language="hu"
          :monday-first="true"
          :calendar-button="true"
          calendar-button-icon="fi-calendar"
        />
      </div>
      <span
        v-if="!isDateValid"
        class="form-error is-visible"
      >A dátum megadása kötelező</span>
    </div>
    <div class="medium-12 columns">
      <div class="row expanded align-center">
        <div class="medium-6 columns no-padding-left">
          <label>
            Mettől
            <input
              v-model="startTime"
              type="time"
              :class="{'is-invalid-input': !isTimeValid }"
            >
          </label>
        </div>
        <div class="medium-6 columns no-padding-right">
          <label>
            Meddig
            <input
              v-model="endTime"
              type="time"
              :class="{'is-invalid-input': !isTimeValid }"
            >
          </label>
        </div>
        <span
          v-if="!isTimeValid"
          class="form-error is-visible"
        >
          Érvényes időintervallum megadása kötelező (csak egész órák adhatók meg)</span>
      </div>
    </div>
    <subject-selector />
    <div class="medium-12 columns">
      <label>
        Megjegyzés
        <input
          v-model="comment"
          type="text"
        >
      </label>
    </div>
    <div class="medium-12 columns">
      <button
        class="button"
        @click="closeInterval()"
      >
        Lezárás
      </button>
      <button
        class="button warning"
        @click="openInterval()"
      >
        Megnyitás
      </button>
    </div>
  </div>
</template>

<script>
import SubjectSelector from './SubjectSelector.vue'
import DatePicker from 'vuejs-datepicker'
import { getCookie } from '../../../../processors/cookie'
import errorHandle from '@/processors/errorHandle'
import notify from '@/processors/notify'
import moment from 'moment'

export default {
  components: {
    SubjectSelector,
    DatePicker
  },
  data () {
    return {
      date: null,
      startTime: null,
      endTime: null,
      comment: ''
    }
  },
  computed: {
    isTimeValid () {
      const startTime = this.startTime
      const endTime = this.endTime
      let startHour, endHour, startMin, endMin
      let isValid = false
      if (startTime && endTime) {
        startHour = startTime.slice(0, startTime.indexOf(':'))
        endHour = endTime.slice(0, endTime.indexOf(':'))
        startMin = startTime.slice(startTime.indexOf(':') + 1, startTime.length)
        endMin = endTime.slice(endTime.indexOf(':') + 1, endTime.length)

        if (Number(startHour) < Number(endHour)) {
          if (startMin === '00' && endMin === '00') {
            isValid = true
          }
        }
      }
      return isValid
    },
    isDateValid () {
      return moment(this.date).isValid()
    }
  },
  methods: {
    closeInterval () {
      const self = this
      const closeData = self.getFormData()
      this.$http({
        url: this.$store.state.url.closeReservationCheck,
        method: 'POST',
        params: { userID: getCookie('userID'), token: getCookie('token') },
        body: JSON.stringify({ data: closeData })
      }).then(response => {
        console.log('--- success close check request')
        console.log(response)
        const hasReservations = response.body.hasReservations
        let notifyText = 'Biztosan lezárja?'
        if (hasReservations) {
          notifyText = 'Az adott időintervallumban aktív foglalások vannak, biztosan lezárja ?'
        }
        notify.confirmation(notifyText)
          .then(() => {
            // confirm
            closeMe()
          }, () => {
            // cancel
            console.log('close interval canceled')
          })
      }, error => {
        errorHandle.httpError(error)
      })

      function closeMe () {
        const selectElem = document.getElementById('selectedSubjects')
        const options = selectElem && selectElem.options
        const isSubjectValid = options.length > 0

        if (self.isTimeValid && self.isDateValid && isSubjectValid) {
          self.$http({
            url: self.$store.state.url.closeReservation,
            method: 'POST',
            params: { userID: getCookie('userID'), token: getCookie('token') },
            body: JSON.stringify({ data: closeData })
          }).then(response => {
            console.log('--- close timetable is success: ')
            console.log(closeData)
            self.$emit('fetchCloseDataByYear')
            notify.success(response.body.msg)
          }, error => {
            errorHandle.httpError(error)
          })
        } else {
          alert('some data is invalid')
        }
      }
    },
    openInterval () {
      const self = this

      notify.confirmation('Biztosan megnyitja?')
        .then(() => {
          openMe()
        }, () => {
          console.log('open interval canceled')
        })

      function openMe () {
        const selectElem = document.getElementById('selectedSubjects')
        const options = selectElem && selectElem.options
        const isSubjectValid = options.length > 0
        const openData = self.getFormData()

        if (self.isTimeValid && self.isDateValid && isSubjectValid) {
          self.$http({
            url: self.$store.state.url.openReservation,
            method: 'DELETE',
            params: { userID: getCookie('userID'), token: getCookie('token') },
            body: JSON.stringify({ data: openData })
          }).then(response => {
            console.log('--- open timetable is success: ')
            console.log(openData)
            self.$emit('fetchCloseDataByYear')
            notify.success(response.body.msg)
          }, error => {
            errorHandle.httpError(error)
          })
        } else {
          alert('some data is invalid')
        }
      }
    },
    getFormData () {
      const selectedSubjects = []
      const selectElem = document.getElementById('selectedSubjects')
      const options = selectElem && selectElem.options
      let opt

      for (let i = 0; i < options.length; i++) {
        opt = options[i]
        selectedSubjects.push(opt.value)
      }

      return {
        startDate: moment(this.date).format('YYYY-MM-DD'),
        endDate: moment(this.date).format('YYYY-MM-DD'),
        startTime: this.startTime.slice(0, this.startTime.indexOf(':')),
        endTime: this.endTime.slice(0, this.endTime.indexOf(':')),
        comment: this.comment,
        subjects: selectedSubjects
      }
    }
  }
}
</script>

<style scoped>
  .no-padding-left {
    padding-left: 0;
  }

  .no-padding-right {
    padding-right: 0;
  }
</style>
