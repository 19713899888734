<template>
  <div class="loginContainer">
    <img
      :src="defaultLogoUrl"
      alt="logo"
    >
    <h3>Foglaljpályát pultos rendszer <small>v{{ currentVersion }}</small></h3>
    <h4 />
    <form
      id="loginForm"
      @submit.prevent="logMeIn"
    >
      <label>
        <input
          v-model="loginEmail"
          type="email"
          placeholder="sample@mail.com"
          required
        >
      </label>
      <label>
        <input
          v-model="loginPwd"
          type="password"
          placeholder="Jelszó"
          required
        >
      </label>
      <input
        type="submit"
        class="button"
        value="Bejelentkezés"
      >
    </form>
  </div>
</template>

<script>
import sha1 from 'js-sha1'
import { logMeIn } from '../../processors/auth'
import notify from '../../processors/notify'
// import url from '../../api/url'
// import { mapActions } from 'vuex'
import config from '@/config'
import errorHandle from '../../processors/errorHandle'
export default {
  data () {
    return {
      loginEmail: '',
      loginPwd: '',
      currentVersion: config.CURRENT_VERSION,
      defaultLogoUrl: config.DEFAULT_LOGO_URL
    }
  },
  methods: {
    logMeIn () {
      const myEmail = this.loginEmail
      const myPwd = sha1(this.loginPwd)
      console.log(myEmail)
      console.log(myPwd)

      this.$http(
        {
          url: this.$store.state.url.logIn,
          method: 'POST',
          body: {
            email: myEmail,
            password: myPwd
          }
        }).then(response => {
        // success callback
        logMeIn(response.body.userID, response.body.token)
      }, error => {
        // error callback
        if (Number(error.status) === 403) {
          notify.warning('Hibás felhasználónév/jelszó')
        } else {
          // notify.error('Bejenlentkezési hiba')
          errorHandle.httpError(error)
        }
      })
    }
  }
}
</script>

<style scoped>

  img {
    max-width: 150px;
    border-radius: 15%;
  }

  .loginContainer {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }
</style>
