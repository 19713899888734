<template>
  <div id="RuleModal" class="scrollable-modal">
    <modal
      name="RuleModal"
      height="auto"
      width="600px"
      @before-open="beforeModalOpen"
    >
      <!--<h5 class="text-center bottom-border-separator">Nyitvatartási szabály</h5>-->
      <div v-if="isLoaded" class="modal-content">
        <label
          >Típus
          <select id="subject_type" v-model="rule.subject_type" type="text">
            <option v-for="st in subjectTypeList" :key="st.ID" :value="st.ID">
              {{ st.name }}
            </option>
          </select>
        </label>

        <label
          >Periódus
          <select id="period_id" v-model="rule.period_id" type="text">
            <option
              v-for="period in periodList"
              :key="period.id"
              :value="period.id"
            >
              {{ period.name }}
            </option>
          </select>
        </label>

        <div class="row">
          <div class="medium-6 columns">
            <label
              >Érvényesség kezdete
              <input
                id="valid_from"
                v-model="rule.valid_from"
                type="date"
                :class="{ 'is-invalid-input': showError.valid_from }"
              />
              <span v-if="showError.valid_from" class="form-error is-visible"
                >Kitöltése kötelező</span
              >
            </label>
          </div>
          <div class="medium-6 columns">
            <label
              >Érvényesség vége
              <input
                id="valid_to"
                v-model="rule.valid_to"
                type="date"
                :class="{ 'is-invalid-input': showError.valid_to }"
              />
              <span v-if="showError.valid_to" class="form-error is-visible"
                >Kitöltése kötelező</span
              >
            </label>
          </div>
        </div>

        <label
          >Hét napja
          <select id="weekday" v-model="rule.weekday" type="text">
            <option :value="0">Hétfő</option>
            <option :value="1">Kedd</option>
            <option :value="2">Szerda</option>
            <option :value="3">Csütörtök</option>
            <option :value="4">Péntek</option>
            <option :value="5">Szombat</option>
            <option :value="6">Vasárnap</option>
          </select>
        </label>

        <div class="row">
          <div class="medium-6 columns">
            <label
              >Mettől (óra)
              <input
                id="from_hour"
                v-model="rule.from_hour"
                type="number"
                :class="{ 'is-invalid-input': showError.from_hour }"
              />
              <span v-if="showError.from_hour" class="form-error is-visible"
                >Kitöltése kötelező</span
              >
            </label>
          </div>
          <div class="medium-6 columns">
            <label
              >Meddig (óra)
              <input
                id="to_hour"
                v-model="rule.to_hour"
                type="number"
                :class="{ 'is-invalid-input': showError.to_hour }"
              />
              <span v-if="showError.to_hour" class="form-error is-visible"
                >Kitöltése kötelező</span
              >
            </label>
          </div>
        </div>

        <div class="medium-12 columns text-right top-border-separator">
          <button class="button" @click="saveChanges()">Mentés</button>
          <button class="button secondary" @click="close()">Mégse</button>
        </div>
      </div>

      <button
        class="close-button"
        data-close
        aria-label="Close modal"
        type="button"
        @click="close()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </modal>
  </div>
</template>

<script>
import { getCookie } from "../../../processors/cookie";
import errorHandle from "@/processors/errorHandle";
import moment from "moment";
import notify from "@/processors/notify";

const EMPTY_RULE = {
  valid_from: null,
  valid_to: null,
  from_hour: null,
  to_hour: null,
};

export default {
  data() {
    return {
      rule: JSON.parse(JSON.stringify(EMPTY_RULE)),
      periodList: false,
    };
  }, //data
  computed: {
    showError() {
      return {
        valid_from: false,
        valid_to: false,
        from_hour: false,
        to_hour: false,
      };
    },
    subjectTypeList() {
      return this.$store.state.subjectTypes;
    },
    isLoaded() {
      return this.periodList !== false;
    },
  }, //computed
  methods: {
    beforeModalOpen(event) {
      if (event.params && event.params.rule) {
        this.rule = JSON.parse(JSON.stringify(event.params.rule));
        this.rule.valid_from = moment(this.rule.valid_from).format(
          "yyyy-MM-DD"
        );
        this.rule.valid_to = moment(this.rule.valid_to).format("yyyy-MM-DD");
      } else {
        this.rule = JSON.parse(JSON.stringify(EMPTY_RULE));
      }

      if (this.periodList === false) this.fetchPeriodList();
    },
    fetchPeriodList() {
      this.$http({
        url: this.$store.state.url.getPeriodList,
        method: "GET",
        params: { userID: getCookie("userID"), token: getCookie("token") },
      }).then(
        (response) => {
          this.periodList = response.body;
        },
        (error) => {
          errorHandle.httpError(error);
        }
      );
    },
    close() {
      this.$modal.hide("RuleModal");
    },
    saveChanges() {
      let valid = true;
      if (valid) {
        this.$http({
          url: this.$store.state.url.updateRule,
          method: "POST",
          params: { userID: getCookie("userID"), token: getCookie("token") },
          body: JSON.stringify({ data: this.rule }),
        }).then(
          (response) => {
            console.log("--- success modification");
            notify.success(response.body.msg);
            this.$modal.hide("RuleModal");
            this.$emit('saved');
          },
          (error) => {
            errorHandle.httpError(error);
          }
        ); /*.finally(() => {
          this.$modal.hide('RuleModal')
        })*/
      } else {
        notify.warning("Minden mezőt helyesen kell kitölteni");
      }
    },
  }, //methods
};
</script>
