<template>
  <div>
    <div class="row expanded collapse">
      <div class="medium-1 columns">
        <left-menu />
      </div>
      <!-- <section> -->
      <div class="medium-11 columns main-content-container">
        <div class="row expanded">
          <div class="medium-3 columns">
            <date-picker
              v-model="dateFilter"
              format="yyyy. MM. dd."
              language="hu"
              :monday-first="true"
              :calendar-button="true"
              calendar-button-icon="fi-calendar"
            />
          </div>
          <div class="medium-4 columns">
            <button
              class="button"
              @click="getDailyReport"
            >
              Lekérdezés
            </button>
          </div>
        </div>
        <div class="row expanded">
          <div class="medium-12 columns">
            <daily-report-table :report-rows="dailyReportFetchedData" />
          </div>
        </div>
      </div>
      <!-- </section> -->
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import LeftMenu from '../../left_menu/LeftMenu'
import DatePicker from 'vuejs-datepicker'
import { getCookie } from '../../../processors/cookie'
import errorHandle from '@/processors/errorHandle'
import DailyReportTable from './components/DailyReportTables'

export default {
  components: {
    LeftMenu,
    DatePicker,
    DailyReportTable
  },
  data () {
    return {
      dateFilter: moment().format('YYYY-MM-DD'),
      dailyReportFetchedData: {}
    }
  },
  methods: {
    getDailyReport () {
      this.$http({
        url: this.$store.state.url.dailyReport,
        method: 'GET',
        params: { userID: getCookie('userID'), token: getCookie('token'), date: moment(this.dateFilter).format('YYYY-MM-DD') }
      }).then((response) => {
        this.dailyReportFetchedData = response.body
        console.log('--- dailyreport table fetched: ')
        console.log(response.body)
      }, error => {
        errorHandle.httpError(error)
      })
    }
  }
}
</script>

<style scoped>
  .main-content-container {
    margin-top: 1rem;
    max-height: 100vh;
  }
</style>
