<template>
  <div class="scrollable-modal">
    <modal
      name="attendedModal"
      height="500px"
      draggable=".modal-content"
      @before-open="beforeModalOpen"
      @closed="modalClose"
    >
      <div class="modal-content">
        <h5 class="text-center bottom-border-separator">
          Felhasznált alkalom kezelése
        </h5>
        <div class="row">
          <!-- reservation details -->
          <div class="medium-12 columns">
            <div class="medium-12 columns">
              <table class="unstriped">
                <tbody>
                  <tr>
                    <td>Név</td>
                    <td>{{ currentGuest.name }}</td>
                  </tr>
                  <tr>
                    <td>Felhasználó típus</td>
                    <td>{{ getGuestTypeName(currentGuest.guestType) }}</td>
                  </tr>
                  <tr>
                    <td>Kezdés</td>
                    <td>{{ getFormattedStartTime(startTime) }}</td>
                  </tr>
                  <tr>
                    <td>Hossz</td>
                    <td>{{ reservationLength }} perc</td>
                  </tr>
                  <tr>
                    <td>Pálya</td>
                    <td>{{ getSubjectText(subjectID) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- ./reservation details -->
          <div v-if="extraOptions && extraOptions.length > 0" class="medium-12 columns">
            <h6 class="text-center">Opciók</h6>
            <timetable-extra-options :is-editable="false"
                                     :extra-options="extraOptions"/>
          </div>
          <!-- time block history -->
          <div
            v-if="isShowTimeBlockHistory"
            class="medium-12 columns"
          >
            <h5 class="text-center">
              Pálya történet
            </h5>
            <time-block-history-table />
          </div>
          <!-- ./ time block history -->
          <!-- action btn -->
          <div class="medium-12 columns text-right top-border-separator">
            <button
              class="button"
              @click="revertTransactions()"
            >
              Visszavonás
            </button>
            <button
              class="button"
              @click="showTimeBlockHistory()"
            >
              Pálya történet mutatása
            </button>
            <router-link
              class="button"
              to="Diary"
            >
              Felhasználó tranzakciói
            </router-link>
            <button
              class="button secondary"
              @click="closeModal()"
            >
              Mégse
            </button>
          </div>
          <!-- ./action btn -->
        </div>
      </div>
      <button
        class="close-button"
        data-close
        aria-label="Close modal"
        type="button"
        @click="closeModal()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </modal>
  </div>
</template>

<script>
import TimeBlockHistoryTable from './TimeBlockHistoryTable.vue'
import TimetableExtraOptions from "@/components/views/reservation/components/schedule/components/TimetableExtraOptions";
import errorHandle from '@/processors/errorHandle'
import { getCookie } from '@/processors/cookie'
// import notify from '@/processors/notify'
import moment from 'moment'
export default {
  data () {
    return {
      subjectID: null,
      subjectName: null,
      subjectTypeName: null,
      startTime: null,
      reservationLength: null,
      isShowTimeBlockHistory: false,
      timeTableID: null,
      extraOptions: null
    }
  },
  methods: {
    beforeModalOpen (event) {
      const reservation = event.params.reservation

      this.timeTableID = reservation.timeTableID
      this.subjectID = reservation.subjectID
      this.startTime = reservation.startTime
      this.reservationLength = reservation.length
      this.extraOptions = reservation.extraOptions
    },
    modalClose () {
      this.timeTableID = null
      this.subjectID = null
      this.startTime = null
      this.reservationLength = null
      this.isShowTimeBlockHistory = false
      this.extraOptions = null

      this.$store.dispatch('clearHistoryTable')
    },
    showTimeBlockHistory () {
      this.isShowTimeBlockHistory = true
    },
    closeModal () {
      this.$modal.hide('attendedModal')
    },
    getSubjectText (subjectID) {
      const subjectTypes = this.$store.state.subjectTypes
      let subjectText = ''
      if (subjectID) {
        for (let i = 0; i < subjectTypes.length; i++) {
          const subjectType = subjectTypes[i]
          for (let j = 0; j < subjectType.subjects.length; j++) {
            const subject = subjectType.subjects[j]
            if (Number(subjectID) === Number(subject.ID)) {
              subjectText = '' + subjectType.name + ' ' + subject.name
              break
            }
          }
        }
      }
      return subjectText
    },
    getFormattedStartTime (startTime) {
      return moment(startTime).format('YYYY.MM.DD HH:mm')
    },
    getGuestTypeName (guestTypeKey) {
      let guestTypeName = ''
      const guestTypes = this.$store.state.availableGuestTypes
      if (guestTypeKey) {
        for (let i = 0; i < guestTypes.length; i++) {
          const guestType = guestTypes[i]
          if (guestType.key === guestTypeKey) {
            guestTypeName = guestType.name
            break
          }
        }
      }
      return guestTypeName
    },
    revertTransactions () {
      /* eslint-disable */
      let self = this

      this.$http({
        url: this.$store.state.url.getTransactionRowIDsForAttended,
        method: 'GET',
        params: { userID: getCookie('userID'), token: getCookie('token'), timeTableID: this.timeTableID }
      }).then((response) => {
        let revertableTransactionRowsID = response.body
        console.log('***********************************')
        console.log('--- get revert transaction rows ID success: ')
        console.log(revertableTransactionRowsID)
        console.log('***********************************')
        if (revertableTransactionRowsID.hasOwnProperty('length')) {
          if (revertableTransactionRowsID.length > 0) {
            revertTransaction(revertableTransactionRowsID, 0)
          }
        }
      }, error => {
        errorHandle.httpError(error)
      })

      function revertTransaction (revertableTransactionRowsID, currentIndex) {
        self.$http({
          url: self.$store.state.url.transactionRevert,
          method: 'DELETE',
          params: { userID: getCookie('userID'), token: getCookie('token') },
          body: JSON.stringify({data: {transactionRowID: revertableTransactionRowsID[currentIndex].id}})
        }).then((response) => {
          // notify.success(response.body.msg)
          console.log('--- transaction successfully reverted')
        }, error => {
          errorHandle.httpError(error)
        }).finally(() => {
          currentIndex++
          if (currentIndex < revertableTransactionRowsID.length) {
            revertTransaction(revertableTransactionRowsID, currentIndex)
          } else {
            console.log('--- all transaction reverted')
            self.closeModal()
          }
        })
      }
    }
  },
  computed: {
    currentGuest () {
      return this.$store.state.guest.current
    }
  },
  components: {
    TimeBlockHistoryTable,
    TimetableExtraOptions
  }
}
</script>
