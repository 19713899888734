<template>
  <div class="row expanded">
    <!-- filter box -->
    <div class="medium-12 columns">
      <div class="row expanded filter-box">
        <!-- guest search -->
        <div class="medium-4 columns">
          <user-auto-complete-search @user-selected="fetchTransactions" />
        </div>
        <!-- ./guest search -->
        <!-- guest name -->
        <div class="medium-4 columns">
          <span v-if="this.$store.state.guest.isSelected">{{ this.$store.state.guest.current.name }}</span>
          <span v-else>-</span>
        </div>
        <!-- ./guest name -->
        <!-- filter by transaction type -->
        <div class="medium-4 columns">
          <label>
            <select v-model="typeFilter">
              <option
                v-for="transactionType in transactionTypes"
                :value="transactionType.key"
              >{{ transactionType.name }}</option>
            </select>
          </label>
        </div>
        <!-- ./filter by transaction type -->
      </div>
    </div>
    <!-- ./filter box -->
    <!-- transactions -->
    <div class="medium-12 columns ">
      <div
        v-if="this.$store.state.guest.isSelected"
        class="row expanded shame-table box-border"
      >
        <div
          v-for="head in currentTableData"
          :class="'head-' + head.key"
          class="column"
        >
          <div class="headRow">
            {{ head.headName }}
          </div>
          <div class="cell">
            <ul>
              <li
                v-for="transaction in filteredDiaryTransactions"
                class=""
              >
                <div
                  v-if="head.key === 'isRevertable'"
                  class="transaction"
                >
                  <button
                    v-show="transaction.isRevertable"
                    @click="revertTransaction(transaction)"
                  >
                    <i class="fi-refresh" />
                  </button>
                  <span v-show="!transaction.isRevertable">-</span>
                </div>
                <div
                  v-else
                  class="transaction"
                  :class="{'cancelled-transaction': transaction.status === 'cancelled'}"
                  :title="transaction[head.key]"
                >
                  <span v-if="head.key === 'createdAt'">{{ getFormattedDateTime(transaction[head.key]) }}</span>
                  <span v-else-if="head.key === 'fromDatetime'">{{ getFormattedDateTime(transaction[head.key]) }}</span>
                  <span v-else-if="head.key === 'moveFromTime'">{{ getFormattedDateTime(transaction[head.key]) }}</span>
                  <span v-else-if="head.key === 'moveToTime'">{{ getFormattedDateTime(transaction[head.key]) }}</span>
                  <span v-else-if="head.key === 'previousValidUntil'">{{ getFormattedDate(transaction[head.key]) }}</span>
                  <span v-else-if="head.key === 'validUntil'">{{ getFormattedDate(transaction[head.key]) }}</span>
                  <span v-else>{{ transaction[head.key] }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div v-else>
        <no-user-selected />
      </div>
    </div>
    <!-- ./transactions -->
  </div>
</template>

<script>
// eslint-disabled-next-line
import moment from 'moment'
import UserAutoCompleteSearch from '../../reservation/components/UserAutoCompleteSearch.vue'
import NoUserSelected from '../../reservation/components/NoUserSelected.vue'
import notify from '@/processors/notify'
import { getCookie } from '../../../../processors/cookie'
import errorHandle from '@/processors/errorHandle'
import utils, { getCurrentYear, getFormattedDate, getFormattedDateTime } from '@/processors/util'

export default {
  components: {
    UserAutoCompleteSearch,
    NoUserSelected
  },
  props: ['diaryData'],
  data () {
    return {
      dateFilter: getCurrentYear(),
      typeFilter: null
    }
  },
  computed: {
    filteredDiaryTransactions () {
      const allTransactions = this.diaryData.transactions
      const filteredTransactions = []
      const typeFilter = this.typeFilter

      if (typeFilter && allTransactions) {
        for (let i = 0; i < allTransactions.length; i++) {
          const transaction = allTransactions[i]
          if (typeFilter.toString() === 'all') {
            filteredTransactions.push(transaction)
          } else {
            if (transaction.type.toString() === typeFilter.toString()) {
              filteredTransactions.push(transaction)
            }
          }
        }
      }

      return filteredTransactions
    },
    transactionTypes () {
      const transactionTypesArray = Object.values(this.$store.state.transactionTypes)

      transactionTypesArray.sort(function (a, b) {
        const nameA = a.name.toUpperCase()
        const nameB = b.name.toUpperCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      })

      this.typeFilter = 'all'

      return transactionTypesArray
    },
    currentTableData () {
      let curTableData = []
      const diaryData = this.diaryData
      const typeFilter = this.typeFilter

      if (diaryData.hasOwnProperty('transactionTableData')) {
        if (typeFilter) {
          if (diaryData.transactionTableData.hasOwnProperty(typeFilter)) {
            curTableData = diaryData.transactionTableData[typeFilter]
          }
        }
      }
      return curTableData
    }
  },
  created: function () {
    const isGuestSelected = this.$store.state.guest.isSelected
    const transactionTypes = this.$store.state.transactionTypes
    if (isGuestSelected) {
      this.$emit('fetchDiaryByYearByGuest', {
        guestID: this.$store.state.guest.current.ID,
        year: this.dateFilter
      })
    } else {
      // this.$emit('fetchDiaryByDay', moment().format('YYYY-MM-DD'))
    }
    if (transactionTypes.length > 0) {
      if (transactionTypes[0].hasOwnProperty('key')) {
        this.typeFilter = transactionTypes[0].key
      }
    }
  },
  methods: {
    getNumberMoneyText: utils.getNumberMoneyText,
    fetchTransactions () {
      const transactionTypes = this.$store.state.transactionTypes
      this.$emit('fetchDiaryByYearByGuest', {
        guestID: this.$store.state.guest.current.ID,
        year: this.dateFilter
      })
      if (transactionTypes.length > 0) {
        if (transactionTypes[0].hasOwnProperty('key')) {
          this.typeFilter = transactionTypes[0].key
        }
      }
    },
    getFormattedDate: getFormattedDate,
    getFormattedInterval (startDate, endDate) {
      const start = moment(startDate).format('YYYY.MM.DD H:mm')
      const end = moment(endDate).format('H:mm')
      return '' + start + ' - ' + end
    },
    getFormattedDateTime: getFormattedDateTime,
    revertTransaction (transaction) {
      const transactionRowID = transaction.rowID

      console.log('--- delete this transaction row: ' + transactionRowID)
      this.$http({
        url: this.$store.state.url.transactionRevert,
        method: 'DELETE',
        params: { userID: getCookie('userID'), token: getCookie('token') },
        body: JSON.stringify({ data: { transactionRowID: transactionRowID } })
      }).then((response) => {
        const indexOfItem = this.diaryData.transactions.indexOf(transaction)
        if (this.diaryData.transactions[indexOfItem].hasOwnProperty('isRevertable')) {
          this.diaryData.transactions[indexOfItem].isRevertable = false
        }
        if (this.diaryData.transactions[indexOfItem].hasOwnProperty('status')) {
          this.diaryData.transactions[indexOfItem].status = 'cancelled'
        }

        notify.success(response.body.msg)

        console.log('--- transaction successfully reverted')
      }, error => {
        errorHandle.httpError(error)
      })
    },
    getColumnExtraClass (headKey) {
    }
  }
}
</script>

<style scoped>
  .headRow {
    background: #f8f8f8;
    white-space: nowrap;
    overflow: hidden;
    font-weight: bold;
    border-bottom: 1px solid #cacaca;
    padding: 0.5rem 0;
  }

  .column {
    padding: 0;
  }

  .cell {
    white-space: nowrap;
    overflow: hidden;
  }

  .shame-table {
    max-height: 88vh;
    overflow: auto;
  }

  .filter-box {
  }

  .cancelled-transaction {
    text-decoration: line-through;
    font-style: italic;
  }

  ul {
    margin: 0;
    list-style-type: none;
  }

  li {
    padding: 0.5rem 0;
  }

  ul li:nth-child(even) {
    background: #f1f1f1;
  }

  ul li:nth-child(odd) {
    background: #fefefe;
  }

  ul li:nth-child(even):hover {
    /* background: #ececec; */
  }

  button {
    cursor: pointer;
  }

  .transaction {overflow:scroll;}

  .transaction::-webkit-scrollbar { display:none; }

  /* --- column width helper --- */
  .head-invokerName {
    max-width: 250px;
  }

  .head-isRevertable {
    max-width: 150px;
  }

  .head-createdAt {
    max-width: 150px;
  }

  .head-toDatetime {
    max-width: 80px;
  }
  /* --- END column width helper END --- */
</style>
