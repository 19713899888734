<template>
  <div id="userPassEditModal">
    <modal
      name="userPassEditModal"
      @before-open="beforeOpen"
      @closed="modelClosed"
    >
      <div class="modal-content">
        <h5 class="text-center bottom-border-separator">
          Bérlet módosítása
        </h5>
        <div class="row">
          <div class="medium-12 columns">
            <label for="passCurrentOccasion">Bérlet felhasználható alkamai</label>
            <input
              id="passCurrentOccasion"
              type="number"
              :value="getProps.currentOccasion"
            >
            <label for="passValidUntil">Érvényes</label>
            <input
              id="passValidUntil"
              type="date"
              :value="getProps.validUntil"
            >
          </div>
          <div class="medium-12 columns text-right top-border-separator">
            <button
              class="button"
              @click="savePass()"
            >
              Mentés
            </button>
            <button
              class="button secondary"
              @click="closePassEditModal()"
            >
              Mégse
            </button>
          </div>
        </div>
      </div>
      <button
        class="close-button"
        data-close
        aria-label="Close modal"
        type="button"
        @click="closePassEditModal()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </modal>
  </div>
</template>

<script>
import { getCookie } from '../../../../processors/cookie'
import errorHandle from '@/processors/errorHandle'
import notify from '@/processors/notify'

export default {
  data () {
    return {
      passPurchasedId: null,
      validUntil: null,
      currentOccasion: null
    }
  },
  computed: {
    getProps () {
      return {
        passPurchasedID: this.passPurchasedId,
        validUntil: this.validUntil,
        currentOccasion: this.currentOccasion
      }
    }
  },
  methods: {
    beforeOpen (event) {
      const data = event.params

      this.passPurchasedId = data.passPurchasedID
      this.validUntil = data.validUntil
      this.currentOccasion = data.currentOccasion
    },
    modelClosed () {
      this.passPurchasedId = null
      this.validUntil = null
      this.currentOccasion = null
    },
    savePass () {
      const savePassData = {
        passPurchasedID: this.passPurchasedId,
        validUntil: document.getElementById('passValidUntil').value,
        occasionDifference: document.getElementById('passCurrentOccasion').value - this.currentOccasion
      }

      console.log('--- save pass data: ')
      console.log(savePassData)
      this.$http({
        url: this.$store.state.url.passes,
        method: 'PUT',
        params: { userID: getCookie('userID'), token: getCookie('token') },
        body: JSON.stringify({ data: savePassData })
      }).then(response => {
        notify.success(response.body.msg)
        console.log('--- success pass modification')
        // this.$modal.hide('userPassEditModal')
        // this.$modal.hide('userPassManageModal')
      }, error => {
        errorHandle.httpError(error)
      }).finally(() => {
        this.$modal.hide('userPassEditModal')
        this.$modal.hide('userPassManageModal')
      })
    },
    closePassEditModal () {
      this.$modal.hide('userPassEditModal')
    }
  }
}
</script>
