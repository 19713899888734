<template>
  <div class="row expanded">
    <!-- filter block -->
    <div class="filterBlock medium-12 columns">
      <div class="row expanded">
        <!-- text search filter -->
        <div class="medium-4 columns">
          <input
            v-model="searchFilter"
            type="text"
            placeholder="Keresés a megjegyzésekben"
          >
        </div>
        <!-- ./text search filter -->
        <!-- date filter -->
        <div class="medium-4 columns">
          <label for="dateFilter">
            <select
              id="dateFilter"
              v-model="dateFilter"
            >
              <option
                v-for="date in availableDates"
                :value="date"
              >{{ date }}</option>
            </select>
          </label>
        </div>
        <!-- ./date filter -->
      </div>
    </div>
    <!-- ./filter block -->
    <!-- close table -->
    <div class="medium-12 columns tableBlock">
      <table class="hover">
        <thead>
          <tr>
            <th>Kezdő nap</th>
            <th>Utolsó nap</th>
            <th>Mettől</th>
            <th>Meddig</th>
            <th>Napok</th>
            <th>Pályák</th>
            <th>Megjegyzés</th>
            <th>Megnyitás</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="closeData in filteredCloseData">
            <td>{{ getFormattedDate(closeData.startDate) }}</td>
            <td>{{ getFormattedDate(closeData.endDate) }}</td>
            <td>{{ getFormattedTime(closeData.startTime) }}</td>
            <td>{{ getFormattedTime(closeData.endTime) }}</td>
            <td>
              <span v-show="closeData.day.mon">Hé</span>
              <span v-show="closeData.day.tue">Ke</span>
              <span v-show="closeData.day.wed">Sze</span>
              <span v-show="closeData.day.thu">Csü</span>
              <span v-show="closeData.day.fri">Pé</span>
              <span v-show="closeData.day.sat">Szo</span>
              <span v-show="closeData.day.sun">Vas</span>
            </td>
            <td>
              <span v-for="(subject, index) in closeData.subjects">
                {{ getSubjectText(subject, index, closeData.subjects.length) }}
              </span>
            </td>
            <td>{{ closeData.comment }}</td>
            <td>
              <i
                class="fi-x"
                @click="openCloseInterval(closeData)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- ./close table -->
  </div>
</template>

<script>
import { getCookie } from '../../../../processors/cookie'
import errorHandle from '@/processors/errorHandle'
import notify from '@/processors/notify'
import { getFormattedDate, getCurrentYear } from '../../../../processors/util'
import moment from 'moment'

export default {
  props: ['closeData'],
  data () {
    return {
      dateFilter: getCurrentYear(),
      searchFilter: ''
    }
  },
  computed: {
    availableDates () {
      // eslint-disable-next-line
      let availableDates = [getCurrentYear()]
      if (this.closeData && this.closeData.length > 0) {
        for (const cd of this.closeData) {
          if (cd.hasOwnProperty('endDate') && cd.hasOwnProperty('startDate')) {
            const startDate = moment(cd.startDate).format('YYYY')
            const endDate = moment(cd.endDate).format('YYYY')

            if (!availableDates.includes(startDate)) {
              availableDates.push(startDate)
            }
            if (!availableDates.includes(endDate)) {
              availableDates.push(endDate)
            }
          }
        }
      }

      return availableDates
    },
    filteredCloseData () {
      const allCloseData = this.closeData
      let preFilteredData = []
      const filteredData = []
      const searchFilter = this.searchFilter.toLowerCase()
      const dateFilter = this.dateFilter
      let item, comment

      if (searchFilter.length > 0) {
        for (let i = 0; i < allCloseData.length; i++) {
          item = allCloseData[i]
          comment = item.comment.toLowerCase()
          if (comment.includes(searchFilter)) {
            preFilteredData.push(item)
          }
        }
      } else {
        preFilteredData = allCloseData
      }

      for (const fData of preFilteredData) {
        if (fData.hasOwnProperty('startDate') && fData.hasOwnProperty('endDate')) {
          const startDate = moment(fData.startDate).format('YYYY')
          const endDate = moment(fData.endDate).format('YYYY')
          if (startDate === dateFilter || endDate === dateFilter) {
            filteredData.push(fData)
          }
        } else {
          filteredData.push(fData)
        }
      }

      return filteredData
    }
  },
  created: function () {
    this.$emit('fetchCloseDataByYear')
  },
  methods: {
    fetchCloseDataByYear () {
      this.$emit('fetchCloseDataByYear', this.dateFilter)
    },
    getFormattedDate: getFormattedDate,
    getFormattedTime (time) {
      return time.slice(0, time.lastIndexOf(':'))
    },
    openCloseInterval (closeData) {
      this.$http({
        url: this.$store.state.url.openReservation,
        method: 'DELETE',
        params: { userID: getCookie('userID'), token: getCookie('token') },
        body: JSON.stringify({ data: closeData })
      }).then(response => {
        console.log('--- close timetable is success: ')
        console.log(closeData)
        this.$emit('fetchCloseDataByYear', this.dateFilter)
        notify.success(response.body.msg)
      }, error => {
        errorHandle.httpError(error)
      })
    },
    getSubjectText (subjectID, index, length) {
      const subjectTypes = this.$store.state.subjectTypes

      for (const subjectType of subjectTypes) {
        if (subjectType.hasOwnProperty('subjects')) {
          const subjects = subjectType.subjects
          for (const subject of subjects) {
            if (subject.hasOwnProperty('ID') && subject.hasOwnProperty('name') && subject.hasOwnProperty('type')) {
              if (Number(subject.ID) === Number(subjectID)) {
                const result = '' + subject.type + ' ' + subject.name
                if (index < length - 1) {
                  return result + ' | '
                }
                return result
              }
            }
          }
        }
      }
      if (index < length - 1) {
        return '? | '
      }
      return '?'
    }
  }
}
</script>

<style scoped>
  .filterBlock {
    height: 8vh;
  }

  .tableBlock {
    height: 85vh;
    overflow: auto;
  }

  td > i {
    cursor: pointer;
  }
</style>
