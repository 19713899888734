<template>
  <div id="leftMenu">
    <logo />
    <div class="admin-name text-center">
      {{ adminName }}
    </div>
    <ul class="vertical menu text-center">
      <li title="Foglalások kezelése">
        <router-link
          class=""
          to="Reservation"
        >
          <i class="fi-calendar" />
        </router-link>
      </li>
      <li title="Nyitvatartási szabályok">
        <router-link
          class=""
          to="Rules"
        >
          <i class="fi-unlock" />
        </router-link>
      </li>
      <li title="Lezárás/Megnyitás">
        <router-link
          class=""
          to="Close"
        >
          <i class="fi-lock" />
        </router-link>
      </li>
      <li title="Napló">
        <router-link
          class=""
          to="Diary"
        >
          <i class="fi-archive" />
        </router-link>
      </li>
      <li title="Kassza">
        <router-link
          class=""
          to="Till"
        >
          <i class="fi-dollar-bill" />
        </router-link>
      </li>
      <li title="Napi report">
        <router-link
          class=""
          to="report"
        >
          <i class="fi-clipboard-notes" />
        </router-link>
      </li>
      <!--
      <li title="Statisztika">
        <router-link class="" to="statistic"><i class="fi-graph-bar"></i></router-link>
      </li>
      -->
      <li
        class="text-center"
        title="Kilépés"
      >
        <log-out />
      </li>
    </ul>
  </div>
</template>

<script>
import LogOut from './components/LogOutBtn.vue'
import Logo from './components/Logo.vue'

export default {
  components: {
    LogOut,
    Logo
  },
  computed: {
    adminName () {
      return this.$store.state.admin.name
    }
  }
}
</script>

<style scoped>
  #leftMenu{
    height: 100vh;
    background: #f4f4f4;
    box-shadow: 1px 0 5px 0 rgb(173, 173, 173);
  }

  .menu {
    margin-top: 2rem;
  }

  .menu li{
    width: 100%;
    border-top: 1px solid #dbdbdb;
    font-size: 250%;
  }

  .menu li a{
    color: #3b3b3b;
  }

  .menu li i {
    margin-right: 4px;
    font-weight: 500;
  }

  .admin-name {
    font-style: italic;
    font-size: 95%;
  }
</style>
