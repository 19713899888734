<template>
  <div class="row">
    <div class="medium-8 columns">
      <label for="closeStartDate">Kezdés dátuma</label>
      <date-picker
        id="closeStartDate"
        v-model="startDate"
        format="yyyy. MM. dd."
        language="hu"
        :monday-first="true"
        :calendar-button="true"
        calendar-button-icon="fi-calendar"
      />
    </div>
    <div class="medium-4 columns">
      <label>
        Idő
        <input
          v-model="startTime"
          type="time"
          :class="{'is-invalid-input': !isTimeValid }"
        >
      </label>
    </div>
    <div class="medium-8 columns">
      <label for="closeEndDate">Lezárás vége</label>
      <date-picker
        id="closeEndDate"
        v-model="endDate"
        format="yyyy. MM. dd."
        language="hu"
        :monday-first="true"
        :calendar-button="true"
        calendar-button-icon="fi-calendar"
      />
    </div>
    <div class="medium-4 columns">
      <label>
        Idő
        <input
          v-model="endTime"
          type="time"
          :class="{'is-invalid-input': !isTimeValid }"
        >
      </label>
    </div>
    <div class="medium-12 columns">
      <span
        v-if="!isDateValid"
        class="form-error is-visible"
      >A dátum megadása kötelező</span>
      <span
        v-if="!isTimeValid"
        class="form-error is-visible"
      >
        Csak érvényes egész órák adhatók meg</span>
    </div>
    <div class="medium-12 columns">
      <fieldset class="fieldset">
        <legend>Napok</legend>
        <input
          id="checkbox1"
          v-model="day.mon"
          type="checkbox"
        ><label for="checkbox1">H</label>
        <input
          id="checkbox2"
          v-model="day.tue"
          type="checkbox"
        ><label for="checkbox2">K</label>
        <input
          id="checkbox3"
          v-model="day.wed"
          type="checkbox"
        ><label for="checkbox3">Sze</label>
        <input
          id="checkbox4"
          v-model="day.thu"
          type="checkbox"
        ><label for="checkbox4">Cs</label>
        <input
          id="checkbox5"
          v-model="day.fri"
          type="checkbox"
        ><label for="checkbox5">P</label>
        <input
          id="checkbox6"
          v-model="day.sat"
          type="checkbox"
        ><label for="checkbox6">Szo</label>
        <input
          id="checkbox7"
          v-model="day.sun"
          type="checkbox"
        ><label for="checkbox7">V</label>
        <span
          v-if="!isDayValid"
          class="form-error is-visible"
        >
          Legalább egy nap kiválasztása kötelező</span>
      </fieldset>
    </div>
    <subject-selector />
    <div class="medium-12 columns">
      <label>
        Megjegyzés
        <input
          v-model="comment"
          type="text"
        >
      </label>
    </div>
    <div class="medium-12 columns">
      <button
        class="button warning"
        @click="closeInterval()"
      >
        Lezárás
      </button>
      <button
        class="button"
        @click="openInterval()"
      >
        Megnyitás
      </button>
    </div>
  </div>
</template>

<script>
import SubjectSelector from './SubjectSelector.vue'
import DatePicker from 'vuejs-datepicker'
import { getCookie } from '../../../../processors/cookie'
import errorHandle from '@/processors/errorHandle'
import notify from '@/processors/notify'
import moment from 'moment'

export default {
  components: {
    SubjectSelector,
    DatePicker
  },
  data () {
    return {
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
      day: {
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false,
        sun: false
      },
      comment: ''
    }
  },
  computed: {
    isDateValid () {
      const startDate = this.startDate
      const endDate = this.endDate
      let isValid = false

      if (startDate && endDate) {
        if (moment(startDate).isBefore(endDate)) {
          isValid = true
        }
      }
      return isValid
    },
    isTimeValid () {
      const startTime = this.startTime
      const endTime = this.endTime
      let startMin, endMin
      let isValid = false
      if (startTime && endTime) {
        startMin = startTime.slice(startTime.indexOf(':') + 1, startTime.length)
        endMin = endTime.slice(endTime.indexOf(':') + 1, endTime.length)

        if (startMin === '00' && endMin === '00') {
          isValid = true
        }
      }
      return isValid
    },
    isDayValid () {
      const day = this.day
      let isValid = false
      for (const i in day) {
        if (day.hasOwnProperty(i)) {
          if (day[i]) {
            isValid = true
            break
          }
        }
      }
      return isValid
    }
  },
  created: function () {
  },
  methods: {
    closeInterval () {
      const self = this
      const closeData = self.getFormData()

      this.$http({
        url: this.$store.state.url.closeReservationCheck,
        method: 'POST',
        params: { userID: getCookie('userID'), token: getCookie('token') },
        body: JSON.stringify({ data: closeData })
      }).then(response => {
        console.log('--- success close check request')
        console.log(response)
        const hasReservations = response.body.hasReservations
        let notifyText = 'Biztosan lezárja?'
        if (hasReservations) {
          notifyText = 'Az adott időintervallumban aktív foglalások vannak, biztosan lezárja ?'
        }
        notify.confirmation(notifyText)
          .then(() => {
            // confirm
            closeMe()
          }, () => {
            // cancel
            console.log('close interval canceled')
          })
      }, error => {
        errorHandle.httpError(error)
      })

      function closeMe () {
        const selectElem = document.getElementById('selectedSubjects')
        const options = selectElem && selectElem.options
        const isSubjectValid = options.length > 0

        if (self.isDateValid && self.isTimeValid && self.isDayValid && isSubjectValid) {
          self.$http({
            url: self.$store.state.url.closeReservation,
            method: 'POST',
            params: { userID: getCookie('userID'), token: getCookie('token') },
            body: JSON.stringify({ data: closeData })
          }).then(response => {
            notify.success(response.body.msg)
            console.log('--- close timetable is success: ')
            console.log(closeData)
            self.$emit('fetchCloseDataByYear')
          }, error => {
            errorHandle.httpError(error)
          })
        } else {
          alert('some data is invalid')
        }
      }
    },
    openInterval () {
      const self = this
      notify.confirmation('Biztosan megnyitja?')
        .then(() => {
          openMe()
        }, () => {
          console.log('open interval canceled')
        })

      function openMe () {
        const selectElem = document.getElementById('selectedSubjects')
        const options = selectElem && selectElem.options
        const isSubjectValid = options.length > 0
        const openData = self.getFormData()

        if (self.isDateValid && self.isTimeValid && self.isDayValid && isSubjectValid) {
          self.$http({
            url: self.$store.state.url.openReservation,
            method: 'DELETE',
            params: { userID: getCookie('userID'), token: getCookie('token') },
            body: JSON.stringify({ data: openData })
          }).then(response => {
            notify.success(response.body.msg)
            console.log('--- close timetable is success: ')
            console.log(openData)
            self.$emit('fetchCloseDataByYear')
          }, error => {
            errorHandle.httpError(error)
          })
        } else {
          alert('some data is invalid')
        }
      }
    },
    getFormData () {
      const selectedSubjects = []
      const selectElem = document.getElementById('selectedSubjects')
      const options = selectElem && selectElem.options
      let opt

      for (let i = 0; i < options.length; i++) {
        opt = options[i]
        selectedSubjects.push(opt.value)
      }

      return {
        startDate: moment(this.startDate).format('YYYY-MM-DD'),
        endDate: moment(this.endDate).format('YYYY-MM-DD'),
        startTime: this.startTime,
        endTime: this.endTime,
        day: this.day,
        comment: this.comment,
        subjects: selectedSubjects
      }
    }
  }
}
</script>

<style scoped>
  .fieldset {
    margin-top: 0;
    padding: 0.5rem;
  }

  .form-error {
    margin-bottom: 0.5rem;
  }

  .fieldset .form-error {
    margin-bottom: 0;
  }
</style>
