<template>
  <div id="timetable">
    <div
      id="settingPanel"
      class="row small-up-4"
    >
      <!-- action buttons for calendar -->
      <div class="column">
        <button
          class="button secondary"
          @click="setTableDateToday"
        >
          Ma
        </button>
        <!--<button class="button secondary">Nem lezárt</button>-->
      </div>
      <!-- ./action buttons for calendar -->
      <!-- date picker -->
      <div class="column">
        <date-picker
          :value="currentTableDate"
          format="yyyy. MM. dd."
          language="hu"
          :monday-first="true"
          :calendar-button="true"
          calendar-button-icon="fi-calendar"
          @input="updateCurrentTableDate"
        />

        <!--
        <label>
          <input type="date"
                 v-bind:value="currentTableDate"
                 @change="updateCurrentTableDate($event.target.value)"/>
        </label>
        -->
      </div>
      <!-- ./date picker -->
      <div class="column text-center">
        <h5 class="no-select">
          <i
            class="fi-arrow-left"
            title="Előző nap"
            @click="decreaseCurrentDay"
          />
          {{ getNameOfCurrentDay() }}
          <i
            class="fi-arrow-right"
            title="Következő nap"
            @click="increaseCurrentDay"
          />
        </h5>
      </div>
      <!-- subject type selector -->
      <div class="column">
        <label>
          <select
            :value="currentSubjectTypeID"
            @change="selectSubjectType($event.target.value)"
          >
            <option
              v-for="subjectType in $store.state.subjectTypes"
              :value="subjectType.ID"
            >
              {{ subjectType.name }}</option>
          </select>
        </label>
      </div>
      <!-- ./subject type selector -->
    </div>
    <div class="row collapse">
      <div class="small-1 columns">
        <div class="row collapse">
          <!-- price list -->
          <div class="small-4 columns">
            <div class="row hour-col">
              <div class="timetable-header-block text-center">
                ár
              </div>
              <div
                :style="styleTableContent"
                class="text-center"
              >
                <div
                  v-for="priceData in priceArray"
                  :style="getPriceStyle(priceData)"
                  class="price-block"
                >
                  <div>
                    {{ priceData.price }} Ft
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ./price list -->
          <!-- time list -->
          <div class="small-8 columns">
            <div class="row hour-col">
              <div class="timetable-header-block text-center">
                idő
              </div>
              <div
                :style="styleTableContent"
                class="text-center"
              >
                <div
                  v-for="hour in hourArray"
                  :style="stylePeriod(hour)"
                  class="hour-block"
                >
                  <div>
                    {{ hour }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ./time list -->
        </div>
      </div>
      <div class="small-11 columns">
        <div
          class="row collapse"
          :class="classTableColNumber"
        >
          <div
            v-for="subject in currentTable.subjects"
            class="column"
          >
            <div class="timetable-header-block text-center">
              {{ subject.name }}
            </div>
            <div class="subject-col">
              <div
                v-for="timeBlock in subject.fill"
                :style="getTimeBlockContainerStyle(timeBlock.time, timeBlock.status)"
                class="timeBlockContainer"
              >
                <div
                  class="row collapse text-center"
                  :class="{
                    'timeBlock': !isSmallTimeBlock(timeBlock.time),
                    'smallTimeBlock': isSmallTimeBlock(timeBlock.time),
                    'selected-timeblock': isThisSelected(timeBlock.timeTableID),
                    'deniedAction': isThisDeniedAction(timeBlock.status),
                    'click-enable': !isThisDeniedAction(timeBlock.status),
                    'free': timeBlock.status === 'free',
                    'reserved': timeBlock.status === 'reserved',
                    'attended': timeBlock.status === 'attended',
                    'closed': timeBlock.status === 'closed',
                    'payment_started': timeBlock.status === 'payment_started',
                    'payed': timeBlock.status === 'payed'}"
                  @click="timeBlockClick(timeBlock, subject.ID)"
                >
                  <!-- guest name -->
                  <div class="medium-9 guest-name-container columns align-self-middle">
                    <!--<span class="">{{ getFormattedTime(timeBlock.time.start, timeBlock.time.end) }}</span>-->
                    <span
                      v-if="getReservationName(timeBlock) !== false"
                      class=""
                    >
                      {{ getReservationName(timeBlock) }}
                    </span>
                  </div>
                  <!-- ./guest name -->
                  <!-- guest icon -->
                  <div
                    class="medium-3 columns statusIconCol"
                    :class="{'statusIconColReservedBackground': timeBlockState().isReserved(timeBlock),
                             'attended-ticket': timeBlockState().isTicket(timeBlock),
                             'guest-attended-pass': timeBlockState().isGuest(timeBlock)
                               && timeBlockState().isPass(timeBlock),
                             'trainer-attended-pass': timeBlockState().isTrainer(timeBlock)
                               && timeBlockState().isPass(timeBlock),
                             'permanent-reservation': timeBlockState().isPermanent(timeBlock)
                               && timeBlockState().isReserved(timeBlock)}"
                  >
                    <div class="row statusIconContainer">
                      <div class="small-12 columns align-self-middle">
                        <div
                          class="statusIcon"
                          :title="getStatusIconText(timeBlock).titleText"
                        >
                          {{ getStatusIconText(timeBlock).statusText }}
                        </div>
                      </div>
                      <!--<div></div>
                      &lt;!&ndash; not payed guest ticket &ndash;&gt;
                      <div class="small-12 columns align-self-middle">
                        <div v-show="timeBlockState().isTicket(timeBlock) && timeBlockState().isNotPayed(timeBlock) &&
                                      timeBlockState().isGuest(timeBlock) && !isReservationInPaymentZone(timeBlock)"
                             class="statusIcon"
                             title="Vendég jegy nem lett kifizetve">*J</div>
                      </div>
                      &lt;!&ndash; ./not payed guest ticket &ndash;&gt;
                      &lt;!&ndash; not payed trainer ticket &ndash;&gt;
                      <div class="small-12 columns align-self-middle">
                        <div v-show="timeBlockState().isTicket(timeBlock) && timeBlockState().isNotPayed(timeBlock) &&
                                      timeBlockState().isTrainer(timeBlock) && !isReservationInPaymentZone(timeBlock)"
                             class="statusIcon"
                             title="Oktató jegy nem lett kifizetve">*O</div>
                      </div>
                      &lt;!&ndash; ./not payed trainer ticket &ndash;&gt;
                      &lt;!&ndash; guest payed with pass &ndash;&gt;
                      <div class="small-12 columns align-self-middle">
                        <div v-show="timeBlockState().isGuest(timeBlock) && timeBlockState().isPass(timeBlock) &&
                                     !timeBlockState().isClub(timeBlock)"
                             class="statusIcon"
                             title="Vendég fizetve bérlettel">B</div>
                      </div>
                      &lt;!&ndash; ./guest payed with pass &ndash;&gt;
                      &lt;!&ndash; guest payed with ticket &ndash;&gt;
                      <div class="small-12 columns align-self-middle">
                        <div v-show="timeBlockState().isTicket(timeBlock) && !timeBlockState().isClub(timeBlock) &&
                             timeBlockState().isGuest(timeBlock) && !timeBlockState().isNotPayed(timeBlock)"
                             class="statusIcon"
                             title="Fizetve vendég jegyyel">J</div>
                      </div>
                      &lt;!&ndash; guest payed with ticket &ndash;&gt;
                      &lt;!&ndash; trainer payed with ticket &ndash;&gt;
                      <div class="small-12 columns align-self-middle">
                        <div v-show="timeBlockState().isTicket(timeBlock) && !timeBlockState().isClub(timeBlock) &&
                             timeBlockState().isTrainer(timeBlock) && !timeBlockState().isNotPayed(timeBlock)"
                             class="statusIcon"
                             title="Fizetve oktatói jegyyel">O</div>
                      </div>
                      &lt;!&ndash; trainer payed with ticket &ndash;&gt;
                      &lt;!&ndash; trainer payed with pass &ndash;&gt;
                      <div class="small-12 columns align-self-middle">
                        <div v-show="timeBlockState().isTrainer(timeBlock) && timeBlockState().isPass(timeBlock) &&
                                     !timeBlockState().isClub(timeBlock)"
                             class="statusIcon"
                             title="Oktató fizetve bérlettel">O</div>
                      </div>
                      &lt;!&ndash; ./trainer payed with pass &ndash;&gt;
                      &lt;!&ndash; permanent reservation &ndash;&gt;
                      <div class="small-12 columns align-self-middle">
                        <div v-show="timeBlockState().isPermanent(timeBlock) && timeBlockState().isReserved(timeBlock) &&
                                     !timeBlockState().isClub(timeBlock)"
                             class="statusIcon"
                             title="Állandó foglalás">
                          <span v-show="isReservationInPaymentZone(timeBlock)">!</span>Á
                        </div>
                      </div>
                      &lt;!&ndash; ./permanent reservation &ndash;&gt;
                      &lt;!&ndash; trainer reservation &ndash;&gt;
                      &lt;!&ndash; FIXME: csak ideiglenes a btenisz-nek &ndash;&gt;
                      <div class="small-12 columns align-self-middle">
                        <div v-show="timeBlockState().isTrainer(timeBlock) && timeBlockState().isReserved(timeBlock) &&
                                     !timeBlockState().isClub(timeBlock)"
                             class="statusIcon"
                             title="Oktató foglalás">
                          <span v-show="isReservationInPaymentZone(timeBlock)">!</span>O
                        </div>
                      </div>
                      &lt;!&ndash; ./trainer reservation &ndash;&gt;
                      &lt;!&ndash; guest reservation &ndash;&gt;
                      <div class="small-12 columns align-self-middle">
                        <div v-show="timeBlockState().isGuest(timeBlock) && timeBlockState().isReserved(timeBlock) &&
                                     !timeBlockState().isPermanent(timeBlock) && !timeBlockState().isClub(timeBlock)"
                             class="statusIcon"
                             title="Vendég foglalás">
                          <span v-show="isReservationInPaymentZone(timeBlock)">!</span>V
                        </div>
                      </div>
                      &lt;!&ndash; ./guest reservation &ndash;&gt;
                      &lt;!&ndash; closed &ndash;&gt;
                      <div class="small-12 columns align-self-middle">
                        <div v-show="timeBlockState().isClosed(timeBlock)"
                             class="statusIcon"
                             title="Zárva">-</div>
                      </div>
                      &lt;!&ndash; ./closed &ndash;&gt;
                      &lt;!&ndash; club reservation &ndash;&gt;
                      <div class="small-12 columns align-self-middle">
                        <div v-show="timeBlockState().isReserved(timeBlock) && timeBlockState().isClub(timeBlock)"
                             class="statusIcon"
                             title="Klub foglalás">
                          <span v-show="isReservationInPaymentZone(timeBlock)">!</span>C
                        </div>
                      </div>
                      &lt;!&ndash; ./club reservation &ndash;&gt;
                      &lt;!&ndash; club attended &ndash;&gt;
                      <div class="small-12 columns align-self-middle">
                        <div v-show="timeBlockState().isAttended(timeBlock) && timeBlockState().isClub(timeBlock)
                             && !timeBlockState().isNotPayed(timeBlock)"
                             class="statusIcon"
                             title="Klub fizetve">C</div>
                      </div>
                      &lt;!&ndash; ./club attended &ndash;&gt;
                      &lt;!&ndash; season reservation/attended &ndash;&gt;
                      <div class="small-12 columns align-self-middle">
                        <div v-show="timeBlockState().isAttended(timeBlock) && !timeBlockState().isNotPayed(timeBlock)
                             && timeBlockState().isSeason(timeBlock)"
                             class="statusIcon"
                             title="Szezon foglalás">S</div>
                      </div>
                      &lt;!&ndash; ./season reservation/attended &ndash;&gt;
                      <div></div>-->
                    </div>
                  </div>
                  <!-- ./guest icon -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <schedule-style-tow-icon-legend />
    <new-reservation-modal />
    <edit-reservation-modal />
    <attended-modal />
  </div>
</template>

<script>
import NewReservationModal from './components/NewReservationModal.vue'
import EditReservationModal from './components/EditReservationModal.vue'
import AttendedModal from './components/AttendedModal.vue'
import ScheduleStyleTowIconLegend from './components/ScheduleStyleTowIconLegend.vue'
import DatePicker from 'vuejs-datepicker'
import moment from 'moment'
// import {getCookie} from '../../../../../processors/cookie'
export default {
  components: {
    NewReservationModal,
    EditReservationModal,
    AttendedModal,
    ScheduleStyleTowIconLegend,
    DatePicker
  },
  data () {
    return {
      tableContentHeight: 80,
      curTableData: {
        firstHour: 1,
        lastHour: 1
      },
      historyTable: [],
      currentHour: moment().format('H')
    }
  },
  computed: {
    isThisDeniedAction () {
      return function (status) {
        const isUserSelected = this.$store.state.guest.isSelected
        let isDenied = false
        if (status === 'free') {
          if (!isUserSelected) {
            isDenied = true
          }
        } else if (status === 'closed') {
          isDenied = true
        }
        return isDenied
      }
    },
    isThisSelected () {
      return function (timeTableID) {
        const currentSelectedTimeBlock = this.$store.state.table.selectedTimeTableID
        let isThisSelected = false
        if (Number(currentSelectedTimeBlock) === Number(timeTableID)) {
          isThisSelected = true
        }
        return isThisSelected
      }
    },
    currentSubjectTypeID () {
      return this.$store.state.table.currentSubjectTypeID
    },
    currentTableDate () {
      return this.$store.state.table.currentTableDate
    },
    classTableColNumber () {
      if (this.$store.state.table.subjectType.hasOwnProperty(this.$store.state.table.currentSubjectTypeID)) {
        return 'small-up-' + this.$store.state.table.subjectType[this.$store.state.table.currentSubjectTypeID].subjects.length
      }
    },
    styleTableContent () {
      return {
        height: this.tableContentHeight + 'vh',
        width: '100%'
      }
    },
    styleUnitHeight () {
      return {
        height: this.hourHeightUnitVh + 'vh'
      }
    },
    stylePeriod () {
      return function (hour) {
        if (this.currentTable.hasOwnProperty('periods')) {
          const periods = this.currentTable.periods
          let color = 'FFF'
          for (let i = 0; i < periods.length; i++) {
            if (parseInt(hour) >= parseInt(periods[i].firstHour) &&
              parseInt(hour) < parseInt(periods[i].lastHour)) {
              color = periods[i].color
            }
          }

          return {
            background: '#' + color,
            height: this.hourHeightUnitVh + 'vh'
          }
        }
      }
    },
    currentTable () {
      if (this.$store.state.table.hasOwnProperty('subjectType')) {
        if (this.$store.state.table.subjectType.hasOwnProperty(this.$store.state.table.currentSubjectTypeID)) {
          const curTable = this.$store.state.table.subjectType[this.$store.state.table.currentSubjectTypeID]
          this.curTableData = { firstHour: curTable.firstHour, lastHour: curTable.lastHour }
          return curTable
        } else {
          return { subjects: [] }
        }
      } else {
        return { subjects: [] }
      }
    },
    hourHeightUnitVh () {
      const startTime = this.curTableData.firstHour
      const endTime = this.curTableData.lastHour
      return (this.tableContentHeight / (endTime - startTime))
    },
    hourArray () {
      const hours = []
      for (let i = this.curTableData.firstHour; i <= this.curTableData.lastHour - 1; i++) {
        hours.push(i + ':00')
      }
      return hours
    },
    priceArray () {
      const currentTable = this.currentTable
      const guestTypeID = this.getGuestTypeIDByKey('guest')
      const result = []
      if (currentTable.hasOwnProperty('periods')) {
        for (const period of currentTable.periods) {
          if (period.hasOwnProperty('price')) {
            if (period.price.hasOwnProperty(guestTypeID)) {
              result.push(
                {
                  price: period.price[guestTypeID],
                  color: period.color,
                  length: period.lastHour - period.firstHour
                })
            } else {
              result.push({ price: '-' })
            }
          } else {
            // hasn't any price for this period
            result.push({ price: '-' })
          }
        }
      }
      return result
    },
    isReservationInPaymentZone () {
      // TODO: add offset??? for check
      return function (timeBlock) {
        const currentHour = Number(this.currentHour)
        // console.log(currentHour)
        if (currentHour) {
          if (timeBlock.hasOwnProperty('time') && timeBlock.hasOwnProperty('guest') && timeBlock.hasOwnProperty('time') &&
              timeBlock.hasOwnProperty('status') && timeBlock.status === 'reserved') {
            if (moment().isSame(moment(timeBlock.time.start), 'day')) {
              const reservationStartHour = Number(moment(timeBlock.time.start || moment()).format('H'))
              const guestType = timeBlock.guest.type || 'guest'
              const guestTypePaymentZone = Number(this.$store.state.client.reservationRules.payment_zone[guestType] ||
                this.$store.state.client.reservationRules.payment_zone.guest || 24)
              return reservationStartHour <= (currentHour + guestTypePaymentZone)
            }
          }
          return false
        }
        return false
      }
    },
    getStatusIconText () {
      return function (timeBlock) {
        let statusText = ''
        let titleText = ''
        const isResInPaymentZone = this.isReservationInPaymentZone(timeBlock)
        const isNotPayed = timeBlockState().isNotPayed(timeBlock)
        const isReserved = timeBlockState().isReserved(timeBlock)
        const isAttended = timeBlockState().isAttended(timeBlock)
        const isGuest = timeBlockState().isGuest(timeBlock)
        const isTrainer = timeBlockState().isTrainer(timeBlock)
        const isClub = timeBlockState().isClub(timeBlock)
        const isTicket = timeBlockState().isTicket(timeBlock)
        const isPass = timeBlockState().isPass(timeBlock)
        const isSeason = timeBlockState().isSeason(timeBlock)
        const isPermanent = timeBlockState().isPermanent(timeBlock)
        const isPaymentStarted = timeBlockState().isPaymentStarted(timeBlock)
        const isPayed = timeBlockState().isPayed(timeBlock)

        if (isResInPaymentZone) { statusText += '!'; titleText += 'Fizetős lemondás, ' }
        if (isNotPayed) { statusText += '*'; titleText += 'Nem fizetett, ' }
        /* reservation */
        if (isPermanent) { statusText += 'Á'; titleText += 'Állandó, ' }
        if (isReserved && isGuest && !isClub) { statusText += 'V'; titleText += 'Vendég foglalás, ' }
        if (isReserved && isTrainer && !isClub) { statusText += 'O'; titleText += 'Oktatói foglalás, ' }
        if (isClub) { statusText += 'C'; titleText += 'Klub foglalás, ' }
        /* ./reservation */
        /* ticket */
        if (isAttended && isGuest && isTicket) { statusText += 'J'; titleText += 'Vendég jeggyel fizetve, ' }
        if (isAttended && isTrainer && isTicket) { statusText += 'O'; titleText += 'Oktatói jeggyel fizetve, ' }
        /* ./ticket */
        if (isSeason) { statusText += 'S'; titleText += 'Szezon, ' }
        if (isAttended && isGuest && isPass) { statusText += 'B'; titleText += 'Vendég bérlettel fizete, ' }
        if (isAttended && isTrainer && isPass) { statusText += 'O'; titleText += 'Oktatói bérlettel fizetve, ' }
        /* online payment */
        if (isPaymentStarted) { statusText += 'X'; titleText += 'Online fizetés elindítva, ' }
        if (isPayed) { statusText += 'F'; titleText += 'Online fizetve, ' }
        if (isAttended && !isTicket && !isPass && !isSeason) { statusText += 'FR  '; titleText += 'Megjelent online fizetett foglaláson, ' }
        /* ./online payment */

        return { statusText: statusText, titleText: titleText }
      }
    }
  },
  created: function () {
  },
  mounted: function () {
    var self = this
    setInterval(function () {
      // console.log('updating ticker :P')
      self.currentHour = moment().format('H')
    }, 5000)
  },
  methods: {
    decreaseCurrentDay () {
      const currentTableDate = this.currentTableDate
      const newTableDate = moment(currentTableDate).subtract(1, 'day')

      console.log(currentTableDate)
      console.log(newTableDate)

      this.updateCurrentTableDate(newTableDate)
    },
    increaseCurrentDay () {
      const currentTableDate = this.currentTableDate
      const newTableDate = moment(currentTableDate).add(1, 'day')

      console.log(currentTableDate)
      console.log(newTableDate)

      this.updateCurrentTableDate(newTableDate)
    },
    getNameOfCurrentDay () {
      if (this.currentTableDate) {
        return moment(this.currentTableDate, 'YYYY-MM-DD', 'hu').format('dddd')
      } else {
        return ''
      }
    },
    selectSubjectType (newSubjectTypeID) {
      console.log('--- Change selected subject type to: ' + newSubjectTypeID)
      this.$store.dispatch('updateSubjectTypeID', { newSubjectTypeID: newSubjectTypeID })
    },
    fetchTable: function (newTableDate) {
      this.$store.dispatch('fetchTableByDate', { date: newTableDate })
    },
    updateCurrentTableDate (newTableDate) {
      this.fetchTable(moment(newTableDate).format('YYYY-MM-DD'))
    },
    datePickerInputChange (e) {
      alert(e)
      console.log('$$$$$$$$$$')
      console.log(e)
    },
    timeBlockClick (timeBlock, subjectID) {
      console.log('--- timeBlock: ')
      console.log(timeBlock)

      const reservationData = {
        status: timeBlock.status,
        startTime: timeBlock.time.start,
        endTime: timeBlock.time.end,
        length: timeBlock.time.length,
        subjectID: subjectID
      }
      // if it's a reservation or payed reservation or payment_started we have more data so add it
      if (timeBlock.status === 'reserved' || timeBlock.status === 'payed' || timeBlock.status === 'payment_started') {
        reservationData.timeTableID = timeBlock.timeTableID
        reservationData.periodID = timeBlock.periodID
        reservationData.subjectTypeID = timeBlock.subjectTypeID
        reservationData.isPermanent = timeBlock.isPermanent === 1
        reservationData.participants = timeBlock.participants
        reservationData.ticketID = timeBlock.ticketID
        reservationData.isClub = timeBlock.isClub
        reservationData.extraOptions = timeBlock.extraOptions
      }
      // if it's an attended reservation we have more data so add it
      if (timeBlock.status === 'attended') {
        reservationData.timeTableID = timeBlock.timeTableID
        reservationData.extraOptions = timeBlock.extraOptions
      }
      // open modal
      // Free
      if (timeBlock.status === 'free') {
        // Need Selected User
        if (this.$store.state.guest.isSelected) {
          this.$modal.show('newReservationModal', { reservation: reservationData })
          // ...
        }
      // Reserved
      } else if (timeBlock.status === 'reserved' || timeBlock.status === 'payed' || timeBlock.status === 'payment_started') {
        // Select the quest who reserved this time
        this.$store.dispatch('fetchGuestByID', { reqUserID: timeBlock.guest.ID }).then(() => {
          this.$store.state.table.selectedTimeTableID = timeBlock.timeTableID
          this.$modal.show('editReservationModal', { reservation: reservationData })
        })
      // Attended
      } else if (timeBlock.status === 'attended') {
        // this.selectUser(timeBlock.guest.ID)
        // Select the quest who reserved this time
        this.$store.dispatch('fetchGuestByID', { reqUserID: timeBlock.guest.ID }).then(() => {
          this.$store.state.table.selectedTimeTableID = timeBlock.timeTableID
          this.$store.dispatch('fetchTimeBlockHistory', { subjectID: subjectID, startDate: timeBlock.time.start })
            .then(() => {
              this.$modal.show('attendedModal', { reservation: reservationData })
            })
        })
      } else {
        // Closed, attended
        // ...
      }
    },
    getFormattedTime (startTime, endTime) {
      return '' + moment(startTime).format('HH:mm') + '-' + moment(endTime).format('HH:mm')
    },
    isSmallTimeBlock (time) {
      return time.length < 60
    },
    getTimeBlockContainerStyle (time, status) {
      const hour = moment(time.start).format('HH:mm')
      const length = time.length
      const timeBlockHeight = this.hourHeightUnitVh * (length / 60)
      const style = {
        background: '#FFF',
        height: timeBlockHeight + 'vh'
      }

      let periodColor = '#FFF'
      if (this.currentTable.hasOwnProperty('periods')) {
        const periods = this.currentTable.periods
        for (let i = 0; i < periods.length; i++) {
          if (parseInt(hour) >= parseInt(periods[i].firstHour) &&
            parseInt(hour) < parseInt(periods[i].lastHour)) {
            periodColor = '#' + periods[i].color
          }
        }
      }

      style.background = periodColor

      return style
    },
    timeBlockState: timeBlockState,
    getReservationName (timeBlock) {
      let name = false
      if (timeBlock.hasOwnProperty('guest')) {
        if (timeBlock.guest.hasOwnProperty('name')) {
          name = timeBlock.guest.name
        }
      }
      return name
    },
    setTableDateToday () {
      this.updateCurrentTableDate(moment().format('YYYY-MM-DD'))
    },
    getGuestTypeIDByKey (guestKey) {
      const availableGuestTypes = this.$store.state.availableGuestTypes
      for (const guestType of availableGuestTypes) {
        if (guestType.key === guestKey) {
          return guestType.ID
        }
      }
      return null
    },
    getPriceStyle (priceData) {
      return {
        background: '#' + priceData.color,
        height: this.hourHeightUnitVh * priceData.length + 'vh'
      }
    }
  }
}

function timeBlockState () {
  function isReserved (timeBlock) {
    return timeBlock.status === 'reserved'
  }
  function isFree (timeBlock) {
    return timeBlock.status === 'free'
  }
  function isClosed (timeBlock) {
    return timeBlock.status === 'closed'
  }
  function isAttended (timeBlock) {
    return timeBlock.status === 'attended'
  }
  function isPaymentStarted (timeBlock) {
    return timeBlock.status === 'payment_started'
  }
  function isPayed (timeBlock) {
    return timeBlock.status === 'payed'
  }
  function isPermanent (timeBlock) {
    let isPermanent = false
    if (timeBlock.hasOwnProperty('isPermanent')) {
      isPermanent = timeBlock.isPermanent
    }
    return isPermanent
  }
  function isTrainer (timeBlock) {
    let isTrainer = false
    if (timeBlock.hasOwnProperty('guest')) {
      if (timeBlock.guest.hasOwnProperty('type')) {
        isTrainer = timeBlock.guest.type === 'trainer'
      }
    }
    return isTrainer
  }
  function isGuest (timeBlock) {
    let isGuest = false
    if (timeBlock.hasOwnProperty('guest')) {
      if (timeBlock.guest.hasOwnProperty('type')) {
        isGuest = timeBlock.guest.type === 'guest'
      }
    }
    return isGuest
  }
  function isTicket (timeBlock) {
    let isTicket = false
    if (timeBlock.hasOwnProperty('usedItemType')) {
      isTicket = timeBlock.usedItemType === 'ticket'
    }
    return isTicket
  }
  function isPass (timeBlock) {
    let isPass = false
    if (timeBlock.hasOwnProperty('usedItemType')) {
      isPass = timeBlock.usedItemType === 'pass'
    }
    return isPass
  }
  function isClub (timeBlock) {
    const isClub = Number(timeBlock.isClub)
    return isClub === 1
  }

  function isNotPayed (timeBlock) {
    const isNotPayed = false
    if (timeBlock.hasOwnProperty('occasionPrice')) {
      if (timeBlock.occasionPrice === 0) {
        return true
      }
    }
    return isNotPayed
  }

  function isSeason (timeBlock) {
    if (timeBlock.hasOwnProperty('usedItemType')) {
      if (timeBlock.usedItemType === 'season_pass') {
        return true
      }
    }
    return false
  }

  return {
    isReserved: isReserved,
    isFree: isFree,
    isClosed: isClosed,
    isPermanent: isPermanent,
    isTrainer: isTrainer,
    isGuest: isGuest,
    isTicket: isTicket,
    isPass: isPass,
    isClub: isClub,
    isAttended: isAttended,
    isNotPayed: isNotPayed,
    isSeason: isSeason,
    isPaymentStarted: isPaymentStarted,
    isPayed: isPayed
  }
}

</script>

<style scoped>
  ul{
    display: inline-block;
    list-style: none;
  }

  #timetable{
    height: 100vh;
    padding: 0.5rem
  }

  .timetable-header-block{
    width: 100%;
    border-top: 1px solid #3b3b3b;
    border-right: 1px solid #3b3b3b;
    font-weight: 600;
    background: #5e5e5e;
    color: white;
  }

  .timeBlockContainer{
    font-style: italic;
    overflow: auto;
    line-height: 1;
    border-bottom: 1px solid #3b3b3b;
  }

  .statusIconCol {
    border-left: 1px solid #3b3b3b;
  }

  .statusIconContainer {
    height: 100%;
  }

  .statusIconColReservedBackground {
    background: #5e5e5e;
  }

  .timeBlock .statusIconCol {
    font-size: 1rem;
  }

  @media only screen and (min-width: 1365px) {
    .timeBlock .statusIconCol {
      font-size: 1.1rem;
    }
  }

  @media only screen and (min-width: 1600px) {
    .timeBlock .statusIconCol {
      font-size: 1.5rem;
    }
  }

  .subject-col {
    border-right: 1px solid #3b3b3b;
    border-top: 1px solid #3b3b3b;
  }

  .deniedAction {
    cursor: not-allowed;
  }

  h5 > i {
    cursor: pointer;
  }

  .selected-timeblock {
    border: 2px solid red !important;
  }

  .closed {
    background: #ffc107;
  }

  .reserved {
    background: #2196f3;
  }

  .attended {
    background: #3adb76;
  }

  .payed {
    background: #21f324;
  }

  .payment_started {
    background: #f34b21;
  }

  .free {
    background: transparent;
  }

  .timeBlock {
    margin: 3% 0 0 0;
    height: 70%;
    width: 90%;
    border: 1px solid #3b3b3b;
  }

  .smallTimeBlock {
    margin: 0 0 0 0;
    height: 100%;
    width: 90%;
    border-left: 1px solid #3b3b3b;
    border-right: 1px solid #3b3b3b;
    /* font-size: 80%; */
  }

  .click-enable {
    cursor: pointer;
  }

  .guest-name-container {
    /* padding-top: 2%; */
    font-size: 1vw;
    white-space: nowrap;
  }

  .hour-block {
    border-top: 1px solid #3b3b3b;
    border-right: 1px solid #3b3b3b;
  }

  .price-block {
    border-top: 1px solid #3b3b3b;
    writing-mode: vertical-lr;
    text-orientation: mixed;
    font-weight: bold;
  }

  .hour-col {
    border-left: 1px solid #3b3b3b;
    border-bottom: 1px solid #3b3b3b;
  }

  .fi-check {
    color: #cc4b37;
  }

  .statusIcon {
    height: 50%;
    color: #FFFFFF;
    white-space: nowrap;
  }

  .guest-attended-pass{
    background: #009846;
    color: #FFFFFF;
  }

  .attended-ticket {
    background: #e5097f;
    color: #FFFFFF;
  }

  .trainer-attended-pass {
    background: #2361b5;
    color: #FFFFFF;
  }

  .trainer-reserved {
    background: #2361b5;
    color: #FFFFFF;
    height: 50%;
  }

  .guest-reserved {
    background: #009846;
    color: #FFFFFF;
    height: 50%;
  }

  .permanent-reservation {
    background: #e5097f;
    color: #FFFFFF;
  }

  .statusIconContainer > .columns {
    padding: 0;
  }

</style>
