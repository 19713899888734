<template>
  <div class="table-container">
    <!--vásárlások listája-->
    <div v-if="reportRows.sale_respons!=null">
      <span style="font-weight: bold; font-size: 14px">Vásárlások listája</span><br><br>
      <table class="hover">
        <thead>
          <tr>
            <th v-for="headRow in reportRows.sale_respons.dailyreportTableData.column_names">
              {{ headRow.headName }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="saleRow in reportRows.sale_respons.dailyreports">
            <td v-if="saleRow.price!=null">
              {{ saleRow.itemName }}
            </td>
            <td v-if="saleRow.price!=null">
              {{ saleRow.soldCount }}
            </td>
            <td v-if="saleRow.price!=null">
              {{ saleRow.userType }}
            </td>
            <td
              v-if="saleRow.price!=null"
              class="money-table-col"
            >
              {{ getNumberMoneyText(saleRow.price) }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>Összesen</td>
            <td />
            <td />
            <td class="money-table-col">
              {{ getNumberMoneyText(sumSales) }}
            </td>
          </tr>
        </tfoot>
      </table>
    </div><br><br>
    <!--befizetés összegzés-->
    <div v-if="reportRows.payments_respons!=null">
      <span style="font-weight: bold; font-size: 14px">Befizetés összegzés</span><br><br>
      <table class="hover">
        <thead>
          <tr>
            <th v-for="headRow in reportRows.payments_respons.dailyreportTableData.column_names">
              {{ headRow.headName }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="paymentRow in reportRows.payments_respons.dailyreports">
            <td>{{ paymentRow.invokerName }}</td>
            <td>{{ paymentRow.guestName }}</td>
            <td>{{ paymentRow.memo }}</td>
            <td class="money-table-col">
              {{ getNumberMoneyText(paymentRow.price) }}
            </td>
            <td class="money-table-col">
              {{ getNumberMoneyText(paymentRow.payment) }}
            </td>
            <td class="money-table-col">
              {{ getNumberMoneyText(paymentRow.balance) }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>Összesen</td>
            <td />
            <td />
            <td class="money-table-col">
              {{ getNumberMoneyText(sumPrices) }}
            </td>
            <td class="money-table-col">
              {{ getNumberMoneyText(sumPays) }}
            </td>
            <td />
          </tr>
        </tfoot>
      </table>
    </div><br><br>
    <!--játékosok foglalásai-->
    <div v-if="reportRows.reservation_respons!=null">
      <span style="font-weight: bold; font-size: 14px">Játékosok foglalásai - összesítés</span><br><br>
      <table class="hover">
        <thead>
          <tr>
            <th v-for="headRow in reportRows.reservation_respons.dailyreportTableData.column_names">
              {{ headRow.headName }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="reservationRow in reportRows.reservation_respons.dailyreports">
            <td>{{ reservationRow.guestName }}</td>
            <td>{{ reservationRow.period }}</td>
            <td>{{ reservationRow.count }}</td>
          </tr>
        </tbody>
      </table>
    </div><br><br>
  </div>
</template>

<script>
import utils from '@/processors/util'

export default {
  props: ['showReport', 'reportRows'],
  data () {
    return {
      sumSales: 0,
      sumPrices: 0,
      sumPays: 0
    }
  },
  watch: {
    reportRows: function (value) {
      let aktSumSales = 0
      let aktSumPrices = 0
      let aktSumPays = 0
      if (value.sale_respons !== null) {
        for (let i = 0; i < value.sale_respons.dailyreports.length; i++) {
          if (value.sale_respons.dailyreports[i].price !== null) {
            aktSumSales = aktSumSales + Number(value.sale_respons.dailyreports[i].price)
          }
        }
      }
      if (value.payments_respons !== null) {
        for (let i = 0; i < value.payments_respons.dailyreports.length; i++) {
          aktSumPrices = aktSumPrices + Number(value.payments_respons.dailyreports[i].price)
          aktSumPays = aktSumPays + Number(value.payments_respons.dailyreports[i].payment)
        }
      }
      this.sumSales = aktSumSales
      this.sumPrices = aktSumPrices
      this.sumPays = aktSumPays
    }
  },
  methods: {
    getNumberMoneyText: utils.getNumberMoneyText
  }
}

</script>

<style scoped>
  .table-container {
    max-height: 80vh;
    overflow: scroll;
  }
</style>
