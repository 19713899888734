import moment from 'moment'

function getGuestTypeName (guestTypeKey) {
  let guestTypeName = ''
  const guestTypes = this.$store.state.availableGuestTypes
  if (guestTypeKey) {
    for (let i = 0; i < guestTypes.length; i++) {
      const guestType = guestTypes[i]
      if (guestType.key === guestTypeKey) {
        guestTypeName = guestType.name
        break
      }
    }
  }
  return guestTypeName
}

function getNumberMoneyText (number) {
  return Number(number).toLocaleString('hu') + ' Ft'
}

function getFormattedDate (date) {
  return moment(date).format('YYYY.MM.DD')
}

function getFormattedDateTime (date) {
  return moment(date).format('YYYY-MM-DD H:mm')
}

function getFormattedTime (date) {
  return moment(date).format('H:mm')
}

function getCurrentYear () {
  return moment().format('YYYY')
}

function getCurrentMonth () {
  return moment().format('MM')
}

function convertBooleanToText (bool) {
  return bool ? 'Igen' : 'Nem'
}

export default {
  getGuestTypeName: getGuestTypeName,
  getNumberMoneyText: getNumberMoneyText,
  getFormattedDate: getFormattedDate,
  getCurrentYear: getCurrentYear,
  getFormattedTime: getFormattedTime,
  getFormattedDateTime: getFormattedDateTime,
  getCurrentMonth: getCurrentMonth,
  convertBooleanToText: convertBooleanToText
}

export {
  getFormattedDate,
  getCurrentYear,
  getFormattedTime,
  getFormattedDateTime,
  getCurrentMonth,
  getNumberMoneyText,
  convertBooleanToText
}
