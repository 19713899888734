<!--suppress JSUnresolvedVariable -->
<template>
  <div>
    <span v-for="option in extraOptions">
      <input
        :id="`timetableExtraOption_${option.ID}`"
        :data-option-id="`${option.ID}`"
        type="checkbox"
        :readonly="!isEditable"
        :checked="!isEditable"
        :onclick="`return ${isEditable}`">
      <label :for="`timetableExtraOption_${option.ID}`">{{ option.name }}</label>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    'extraOptions': {
      type: Array
    },
    'isEditable': {
      type: Boolean,
      default: true
    }
  },
  computed: {

  }
}
</script>

<style scoped>

</style>
