<template>
  <table class="hover">
    <thead>
      <tr>
        <th>Rögzítő</th>
        <th>Felhasználó</th>
        <th>Típus</th>
        <th>Létrehozva</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="historyRow in historyTable">
        <td>{{ historyRow.invokerName }}</td>
        <td>{{ historyRow.userName }}</td>
        <td>{{ getStatusText(historyRow.status) }}</td>
        <td>{{ getFormattedDateTime(historyRow.createdAt) }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { getFormattedDateTime } from '../../../../../../processors/util'
export default {
  computed: {
    historyTable () {
      return this.$store.state.historyTable
    }
  },
  methods: {
    fetchTimeBlockHistory () {
      /*
      this.$http({
        url: this.$store.state.url.getTimeBlockHistory,
        method: 'GET',
        params: { userID: getCookie('userID'), token: getCookie('token'), subjectID: '34', startDate: '2017-11-09 08:00' }
      }).then((response) => {
        this.historyTable = response.body
        console.log('--- fetchTimeBlockHistory success: ')
        console.log(response.body)
      }, error => {
        errorHandle.httpError(error)
      })
      */
    },
    getStatusText (status) {
      const statuses = {
        free_resign: 'Ingyenes lemondás',
        free_resign_from_close: 'Ingyenes lemondás lezárás miatt',
        miss: 'Nem jelent meg',
        permanent_resign: 'Állandó foglalás lemondás',
        reservation: 'Foglalás',
        resign: 'Lemondás',
        reservation_move: 'Foglalás áthelyezve ide',
        pass_use: 'Fizetve bérlettel',
        ticket_use: 'Fizetve jeggyel'
      }
      let statusText = ' - '

      if (statuses.hasOwnProperty(status)) {
        statusText = statuses[status]
      }

      return statusText
    },
    getFormattedDateTime: getFormattedDateTime
  }
}
</script>

<style scoped>
  table{
    width: 100%;
  }
</style>
