// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import VeeValidate from 'vee-validate'
import VueResource from 'vue-resource'
import { isLoggedIn } from './processors/auth'
import VModal from 'vue-js-modal'
import moment from 'moment'
import VueSocketio from 'vue-socket.io'
import config from './config'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

/* eslint-disable */
import io from 'socket.io-client'

Vue.use(VeeValidate)
Vue.use(VueResource)
// Vue.use(VueGoodTable)
Vue.use(VModal)

Vue.http.interceptors.push((request, next) => {
  NProgress.start();
  next((response)=>{
    NProgress.done();
  });
});

Vue.use(VueSocketio, config.serverDomain + '/admin', store);

moment.locale('hu')

/*
Vue.config.productionTip = true
Vue.config.devtools = true
Vue.config.silent = false
*/

/* eslint-disable no-new */
export default new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: { App },
  methods: {
    startUp: function () {
      this.$store.dispatch('fetchStartUp').then(() => {
        // TODO: Hibakezelés, ha nem kapta meg a startup adatot
        // console.log('This would be printed after dispatch!!')
      })
    },
    fetchTable: function () {
      let curDate = moment().format('YYYY-MM-DD')
      this.$store.dispatch('fetchTableByDate', {date: curDate})
    },
    fetchAllGuest: function () {
      this.$store.dispatch('fetchAllGuest')
    }
  },
  created: function () {
    if (isLoggedIn()) {
      this.startUp()
      this.fetchAllGuest()
      this.fetchTable()
    }
  },
  sockets:{
    connect: function(){
      console.log('socket connected')
    },
    welcomeInRoom: function(data){
      console.log(data)
    },
    welcomeInNameSpace: function (data) {
      console.log(data)
    }
  },
})
