<template>
  <div class="row expanded collapse">
    <div class="medium-1 columns border-helper">
      <left-menu />
    </div>
    <div class="medium-11 columns text-center border-helper">
      <section>
        <div v-if="isRequiredDataLoaded">
          <till-content
            :till-data="tillFetchedData"
            @till-created="fetchTillTable"
            @fetch-table="fetchTillTable"
          />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import LeftMenu from '../../left_menu/LeftMenu.vue'
import TillContent from './components/TillContent.vue'
import { getCookie } from '../../../processors/cookie'
import errorHandle from '@/processors/errorHandle'
export default {
  components: {
    LeftMenu,
    TillContent
  },
  data () {
    return {
      isRequiredDataLoaded: false,
      tillFetchedData: []
    }
  },
  created: function () {
    this.isRequiredDataLoaded = true
  },
  methods: {
    fetchTillTable (data) {
      this.$http({
        url: this.$store.state.url.getTill,
        method: 'GET',
        params: { userID: getCookie('userID'), token: getCookie('token'), date: data.date }
      }).then((response) => {
        this.tillFetchedData = response.body
        console.log('--- till table fetched: ')
        console.log(response.body)
      }, error => {
        errorHandle.httpError(error)
      })
    }
  }
}
</script>

<style scoped>
  section {
    max-height: 100vh;
    overflow: auto;
  }
</style>
