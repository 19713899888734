<template>
  <div>
    <div
      id="changeEmailModal"
      class="scrollable-modal"
    >
      <modal
        name="changeEmailModal"
        height="370px"
      >
        <div class="modal-content">
          <label>Email*
            <input
              id="userEmail"
              v-model="newEmail"
              type="email"
              :class="{'is-invalid-input' : !isEmailValid}"
            >
            <span
              v-if="!isEmailValid"
              class="form-error is-visible"
            >Email kitöltése kötelező, helyes formátum: minta@mail.hu</span>
          </label>
          <div class="medium-12 columns text-right top-border-separator">
            <button
              class="button"
              @click="saveChanges()"
            >
              Mentés
            </button>
            <button
              class="button secondary"
              @click="hideModal()"
            >
              Mégse
            </button>
          </div>
        </div>
        <button
          class="close-button"
          data-close
          aria-label="Close modal"
          type="button"
          @click="hideModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </modal>
    </div>
  </div>
</template>

<script>
import { getCookie } from '../../../../../../processors/cookie'
import notify from '../../../../../../processors/notify'
import errorHandle from '../../../../../../processors/errorHandle'

export default {
  data () {
    return {
      newEmail: ''
    }
  },
  computed: {
    isEmailValid () {
      const newEmail = this.newEmail
      // eslint-disable-next-line
      let regExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return regExp.test(newEmail)
    },
    changeCheck () {
      const guest = this.$store.state.guest.current
      return guest
    }
  },
  watch: {
    changeCheck: function (guest) {
      const hasNotEmailPrefix = 'nincsEmailCime'
      if (guest.hasOwnProperty('email') && guest.email) {
        if (guest.email.includes(hasNotEmailPrefix)) {
          this.$modal.show('changeEmailModal')
          notify.info('A felhasználónak még nem adta meg az email cím!')
        }
      }
    }
  },
  methods: {
    saveChanges () {
      if (this.isEmailValid) {
        this.$http({
          url: this.$store.state.url.changeEmail,
          method: 'PUT',
          params: {
            userID: getCookie('userID'),
            token: getCookie('token'),
            changeEmailUserID: this.$store.state.guest.current.ID,
            newEmail: this.newEmail
          }
        }).then(response => {
          console.log('--- change email successfully sent')
          notify.success(response.body.msg)
        }, error => {
          errorHandle.httpError(error)
        }).finally(() => {
          this.$modal.hide('changeEmailModal')
        })
      } else {
        notify.warning('Kérjük helyem formátumú ímél címet adjom meg. (minta@mail.com)')
      }
    },
    hideModal () {
      this.$modal.hide('changeEmailModal')
    }
  }
}
</script>
