<template>
  <div class="row expanded">
    <!-- date filter -->
    <div class="medium-4 columns">
      <date-picker
        v-model="dateFilter"
        format="yyyy. MM. dd."
        language="hu"
        :monday-first="true"
        :calendar-button="true"
        calendar-button-icon="fi-calendar"
        @input="reFetchTable()"
      />
    </div>
    <!-- ./date filter -->
    <!--
    <div class="medium-4 columns">
      <label>
        <select>
          <option v-for="type in availableTypes"
                  :value="type.key">{{ type.name }}
          </option>
        </select>
      </label>
    </div>
    -->
    <!-- close current till -->
    <div class="medium-4 columns">
      <button
        class="button"
        @click="closeTill"
      >
        Lezárás
      </button>
    </div>
    <!-- ./close current till -->
    <!-- open till table -->
    <div
      v-if="tillData.openTill"
      class="medium-12 columns till"
    >
      <h3>Nyitott kassza</h3>
      <till-table
        :till-rows="tillData.openTill.transactions"
        :closed-date="tillData.openTill.closedDate"
        :income="tillData.openTill.income"
        :card-income="tillData.openTill.cardIncome"
      />
      <hr>
    </div>
    <!-- ./open till table -->
    <!-- closed till tables -->
    <div
      v-if="hasClosedTill"
      class="medium-12 columns till"
    >
      <h3>Lezárt kasszák</h3>
      <div v-for="closedTill in tillData.closedTills">
        <till-table
          :till-rows="closedTill.transactions"
          :closed-date="closedTill.closedDate"
          :income="closedTill.income"
          :card-income="closedTill.cardIncome"
        />
        <hr>
      </div>
    </div>
    <div
      v-if="!hasOpenTill && !hasClosedTill"
      class="medium-12 columns no-till"
    >
      <i class="fi-minus-circle" />
      <div>A kassza üres</div>
    </div>
    <!-- ./closed till tables -->
  </div>
</template>

<script>
// eslint-disable-next-line
import moment from 'moment'
import TillTable from './TillTable.vue'
import DatePicker from 'vuejs-datepicker'
import { getCookie } from '../../../../processors/cookie'
import errorHandle from '@/processors/errorHandle'
import notify from '@/processors/notify'

export default {
  components: {
    TillTable,
    DatePicker
  },
  props: ['tillData'],
  data () {
    return {
      dateFilter: moment().format('YYYY-MM-DD')
    }
  },
  computed: {
    hasClosedTill () {
      if (this.tillData.hasOwnProperty('closedTills')) {
        return this.tillData.closedTills.length > 0
      }
      return false
    },
    hasOpenTill () {
      if (this.tillData.hasOwnProperty('openTill')) {
        return this.tillData.openTill !== false
      }
      return false
    }
  },
  created: function () {
    this.$emit('till-created', {
      date: moment(this.dateFilter).format('YYYY-MM-DD')
    })
  },
  methods: {
    reFetchTable () {
      this.$emit('fetch-table', {
        date: moment(this.dateFilter).format('YYYY-MM-DD')
      })
    },
    closeTill () {
      this.$http({
        url: this.$store.state.url.closeTill,
        method: 'PUT',
        params: { userID: getCookie('userID'), token: getCookie('token') }
      }).then((response) => {
        console.log('--- till closed ')
        this.reFetchTable()
        notify.success(response.body.msg)
      }, error => {
        errorHandle.httpError(error)
      })
    }
  }
}
</script>

<style scoped>
  .no-till {
    font-size: 2rem;
    color: rgba(194, 193, 190, 0.8);
    text-shadow: 1px 4px 6px #FFF, 0 0 0 #000, 1px 4px 6px #FFF;
    min-height: 300px;
  }

  .no-till > i {
    font-size: 200%;
  }

  .till {
    min-height: 300px;
  }
</style>
