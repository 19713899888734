<template>
  <div
    id="userPurchaseModal"
    class="scrollable-modal"
  >
    <modal
      name="userPurchaseModal"
      height="600px"
      width="1000px"
      @before-open="beforeModalOpen"
      @closed="modalClosed"
    >
      <div class="modal-content">
        <h5 class="text-center bottom-border-separator">
          Vásárlás
        </h5>
        <!-- ticket -->
        <div
          v-if="ticket.isTicket"
          class="callout"
        >
          <h5>Jegy vásárlás</h5>
          <div class="row">
            <!-- ticket name -->
            <div class="medium-2 columns">
              {{ reservationLength/curTicket.timeUnitMins }}x {{ curTicket.name }}
            </div>
            <!-- ./ticket name -->
            <!-- ticket price -->
            <div class="medium-4 columns">
              <label>
                <input
                  id="ticketPrice"
                  v-model="curTicketPrice"
                  type="number"
                >
              </label>
            </div>
            <!-- ./ticket price -->
            <!-- ticket sport card -->
            <div
              v-if="getTicketSportCard && (getTicketSportCard.sportCardPercentageDiscount ||
                getTicketSportCard.sportCardFixDiscount)"
              class="medium-6 columns"
            >
              <div class="row">
                <div
                  v-if="getTicketSportCard.sportCardPercentageDiscount && isPayTicketWithSportCard"
                  class="medium-8 columns"
                >
                  <div class="row">
                    <div class="medium-8 columns">
                      Kedvezmény (%):
                    </div>
                    <div class="medium-4 columns">
                      <label>
                        <input
                          id="sportCardPercentageDiscount"
                          v-model="ticketSportCardDiscountPercentage"
                          type="number"
                        >
                      </label>
                    </div>
                  </div>
                </div>
                <div
                  v-else-if="getTicketSportCard.sportCardFixDiscount && isPayTicketWithSportCard"
                  class="medium-8 columns"
                >
                  <div class="row">
                    <div class="medium-6 columns">
                      Kedvezmény összege:
                    </div>
                    <div class="medium-6 columns">
                      <label>
                        <input
                          id="sportCardFixDiscount"
                          v-model="ticketSportCardDiscountFix"
                          type="number"
                        >
                      </label>
                    </div>
                  </div>
                </div>
                <div
                  v-if="ticket.isTicket"
                  class="medium-4 columns"
                >
                  <div class="row">
                    <div class="medium-6 columns">
                      <span title="Sportkártya">Sportk.</span>
                    </div>
                    <div class="medium-6 columns">
                      <div class="switch">
                        <input
                          id="isPayTicketWithSportCard"
                          v-model="isPayTicketWithSportCard"
                          class="switch-input"
                          type="checkbox"
                          name="isPayTicketWithSportCard"
                        >
                        <label
                          class="switch-paddle"
                          for="isPayTicketWithSportCard"
                        >

                          <span class="show-for-sr">Sportkártya?</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- ./ticket sport card -->
          </div>
        </div>
        <!-- ./ticket -->
        <!-- season pass -->
        <div
          v-if="isSeasonBuy"
          class="callout"
        >
          <h5>Szezon bérlet vásárlás</h5>
          <div class="row">
            <div class="medium-12 columns">
              <table class="unstriped">
                <tbody>
                  <tr>
                    <td>Név</td>
                    <td>{{ currentGuest.name }}</td>
                    <td>Felhasználó típus</td>
                    <td>{{ getGuestTypeName(currentGuest.guestType) }}</td>
                  </tr>
                  <tr>
                    <td>Nap</td>
                    <td>{{ getSeasonReservationDayName }}</td>
                    <td>Óra</td>
                    <td>{{ getSeasonReservationHour }}</td>
                  </tr>
                  <tr>
                    <td>Szezon kezdete</td>
                    <td>{{ getSeasonStart }}</td>
                    <td>Szezon vége</td>
                    <td>{{ getSeasonEnd }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- season price text -->
            <div class="medium-4 columns">
              {{ getSeasonPassName }} ára (Ft):
            </div>
            <!-- ./season price text -->
            <!-- season pass price -->
            <div class="medium-3 columns">
              <label>
                <input
                  id="seasonPassPrice"
                  v-model="seasonPassPrice"
                  type="number"
                >
              </label>
            </div>
            <!-- ./season pass price -->
          </div>
        </div>
        <!-- ./season pass -->
        <!-- pass -->
        <div
          v-if="isPassBuy"
          class="callout"
        >
          <h5>Bérlet vásárlás</h5>
          <table class="hover">
            <thead>
              <tr>
                <th>DB</th>
                <th>Termék</th>
                <th>Érvényes (nap)</th>
                <th>Felhasználható</th>
                <th>Egységár (Ft)</th>
                <th>Összeg (Ft)</th>
                <th><i class="fi-minus" /></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="i in purchasePassKeys">
                <!-- pass count -->
                <td>
                  <label>
                    <select
                      v-model="selectedPassCount[i]"
                      @change="sumPriceRecalculateStart"
                    >
                      <option
                        v-for="count in purchaseCountArray"
                        :value="count.value"
                      >{{ count.text }}</option>
                    </select>
                  </label>
                </td>
                <!-- ./pass count -->
                <!-- select pass -->
                <td>
                  <label>
                    <select v-model="selectedPass[i]">
                      <option
                        v-for="pass in availablePasses"
                        :value="pass"
                      >{{ pass.name }}</option>
                    </select>
                  </label>
                </td>
                <!-- ./select pass -->
                <!-- pass valid day(s) -->
                <td>
                  <label v-if="selectedPass[i]">
                    <input
                      v-model="selectedPass[i].validDay"
                      type="number"
                    >
                  </label>
                </td>
                <!-- ./pass valid day(s) -->
                <!-- pass occasuin(s) -->
                <td>
                  <label v-if="selectedPass[i]">
                    <input
                      v-model="selectedPass[i].occasion"
                      type="number"
                    >
                  </label>
                </td>
                <!-- ./pass occasuin(s) -->
                <!-- pass price -->
                <td>
                  <span v-if="selectedPass[i]">
                    <label>
                      <input
                        v-model="selectedPass[i].price"
                        type="number"
                      >
                    </label>
                  </span>
                  <span v-else>0 Ft</span>
                </td>
                <!-- ./pass price -->
                <!-- sum price of selected pass -->
                <td>
                  <span v-if="selectedPass[i] && selectedPassCount[i]">
                    {{ selectedPass[i].price * selectedPassCount[i] }}&nbsp;Ft
                  </span>
                  <span v-else>0 Ft</span>
                </td>
                <!-- ./sum price of selected pass -->
                <!-- remove pass -->
                <td>
                  <button @click="removePurchasePass(i)">
                    <i class="fi-minus" />
                  </button>
                </td>
              <!-- ./remove pass -->
              </tr>
            </tbody>
          </table>
          <!-- add pass -->
          <div class="row">
            <button
              class="button"
              @click="addPurchasePass()"
            >
              +
            </button>
          </div>
          <!-- ./add pass -->
        </div>
        <!-- ./pass -->
        <!-- other item -->
        <div
          v-if="isOtherItemBuy"
          class="callout"
        >
          <h5>Termék vásárlás</h5>
          <table class="hover">
            <thead>
              <tr>
                <th>DB</th>
                <th>Termék</th>
                <th>Egységár (Ft)</th>
                <th>Összeg (Ft)</th>
                <th><i class="fi-minus" /></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="i in purchaseOtherKeys">
                <!-- other item count -->
                <td>
                  <label>
                    <!--
                   <select v-model="selectedOtherItemCount[i]">
                    <option v-for="count in purchaseCountArray" :value="count.value">{{ count.text }}</option>
                  </select>
                  -->
                    <select
                      v-model="selectedOtherItemCount[i]"
                      @change="sumPriceRecalculateStart"
                    >
                      <option
                        v-for="count in purchaseCountArray"
                        :value="count.value"
                      >{{ count.text }}</option>
                    </select>
                  </label>
                </td>
                <!-- ./other item count -->
                <!-- select other item(s) -->
                <td>
                  <label>
                    <select v-model="selectedOtherItem[i]">
                      <option
                        v-for="otherItem in availableOtherItems"
                        :value="otherItem"
                      >{{ otherItem.name }}</option>
                    </select>
                  </label>
                </td>
                <!-- ./select other item(s) -->
                <!-- other item price -->
                <td>
                  <span v-if="selectedOtherItem[i]">
                    <label>
                      <input
                        v-model="selectedOtherItem[i].price"
                        type="number"
                      >
                    </label>
                  </span>
                  <span v-else>0 Ft</span>
                </td>
                <!-- other item price -->
                <!-- sum price of other item -->
                <td>
                  <span v-if="selectedOtherItem[i] && selectedOtherItemCount[i]">
                    {{ selectedOtherItem[i].price * selectedOtherItemCount[i] }}&nbsp;Ft
                  </span>
                  <span v-else>0 Ft</span>
                </td>
                <!-- ./sum price of other item -->
                <!-- remove other item -->
                <td>
                  <button @click="removePurchaseOtherItem(i)">
                    <i class="fi-minus" />
                  </button>
                </td>
              <!-- ./remove other item -->
              </tr>
            </tbody>
          </table>
          <!-- add other item -->
          <div class="row">
            <button
              class="button"
              @click="addPurchaseOtherItem()"
            >
              +
            </button>
            <!--<button v-show="purchaseOtherCount > 0" class="button secondary" @click="removePurchaseOtherItem()">-</button>-->
          </div>
          <!-- ./add other item -->
        </div>
        <!-- ./other item -->
        <!-- purchase block -->
        <div class="callout">
          <h5>Fizetés</h5>
          <table>
            <thead>
              <tr>
                <th>Fizetendő (Ft)</th>
                <th v-if="currentUserBalance < 0">
                  Tartozás (Ft)
                </th>
                <th>Fizetve (Ft)</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ getSumPrice }} Ft</td>
                <td
                  v-if="currentUserBalance < 0"
                  class="negativeBalance"
                >
                  {{ currentUserBalance }} Ft
                </td>
                <td>
                  <input
                    id="userPayAmount"
                    v-model="payAmount"
                    type="number"
                  >
                </td>
                <td>
                  <button
                    v-if="isPurchaseAvailable"
                    class="button"
                    @click="purchase()"
                  >
                    Vásárlás
                  </button>
                  <button
                    v-else
                    class="button"
                    disabled
                  >
                    Vásárlás
                  </button>
                  <button
                    v-if="isPurchaseAvailable && isEnableLocalBankCardPayment"
                    class="hollow button"
                    @click="purchase(true)"
                  >
                    Fizetés Bankkártyával
                  </button>
                  <button
                    v-else-if="isEnableLocalBankCardPayment"
                    class="hollow button"
                    disabled
                  >
                    Fizetés Bankkártyával
                  </button>
                  <button
                    v-if="currentUserBalance < 0"
                    class="button warning"
                    @click="addDebitToPayAmount"
                  >
                    Tartozás hozzáadás
                  </button>
                  <button
                    class="button secondary"
                    @click="hideModal"
                  >
                    Mégse
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- ./purchase block -->
      </div>
      <button
        class="close-button"
        data-close
        aria-label="Close modal"
        type="button"
        @click="hideModal()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </modal>
  </div>
</template>

<script>
import { getCookie } from '../../../../../../processors/cookie'
import errorHandle from '@/processors/errorHandle'
import notify from '@/processors/notify'
import util from '@/processors/util'
import moment from 'moment'

export default {
  data () {
    return {
      payAmount: 0,
      purchasePassKeys: [],
      purchaseOtherKeys: [],
      purchasePassCount: 0,
      purchaseOtherCount: 0,
      selectedPass: {},
      selectedPassCount: {},
      selectedOtherItem: {},
      selectedOtherItemCount: {},
      ticket: {
        ID: null,
        isTicket: false,
        timeTableID: null,
        isClub: false
      },
      reservationLength: 0,
      curTicketPrice: 0,
      seasonPassPrice: 0,
      isPassBuy: false,
      isOtherItemBuy: false,
      isSeasonBuy: false,
      isPayTicketWithSportCard: false,
      ticketSportCardDiscountPercentage: null,
      ticketSportCardDiscountFix: null,
      seasonReservationData: null,
      isSumPriceRecalculateNeed: false
    }
  },
  computed: {
    isPurchaseAvailable () {
      // objecten belüli változásokat nem érzékel !
      let isAvailable = false
      if (Number.isInteger(Number(this.payAmount))) {
        if (Object.keys(this.selectedPass).length > 0 ||
          Object.keys(this.selectedOtherItem).length > 0 ||
          Number(this.payAmount) !== 0 ||
          this.ticket.isTicket) {
          isAvailable = true
        }
      }
      return isAvailable
    },
    isEnableLocalBankCardPayment () {
      return this.$store.state.client.hasOwnProperty('reservationRules') &&
        this.$store.state.client.reservationRules &&
        this.$store.state.client.reservationRules.hasOwnProperty('enableLocalBankCardPayment') &&
        this.$store.state.client.reservationRules.enableLocalBankCardPayment
    },
    availablePasses () {
      let passes = []
      const availableItems = this.$store.state.availableItems
      const curGuestType = this.$store.state.guest.current.guestType
      if (availableItems.hasOwnProperty(curGuestType)) {
        if (availableItems[curGuestType].hasOwnProperty('pass')) {
          passes = JSON.parse(JSON.stringify(availableItems[curGuestType].pass))
        }
      }
      return passes
    },
    availableOtherItems () {
      let otherItems = []
      const availableItems = this.$store.state.availableItems
      const curGuestType = this.$store.state.guest.current.guestType
      if (availableItems.hasOwnProperty(curGuestType)) {
        if (availableItems[curGuestType].hasOwnProperty('other')) {
          otherItems = JSON.parse(JSON.stringify(availableItems[curGuestType].other))
        }
      }
      return otherItems
    },
    purchaseCountArray () {
      const purchCountArray = []
      for (let i = 1; i < 10; i++) {
        purchCountArray.push({
          value: i,
          text: i + ' db'
        })
      }
      return purchCountArray
    },
    getSumPrice () {
      let sumPrice = 0
      const selectedPasses = this.selectedPass
      const selectedPassCounts = this.selectedPassCount
      const selectedOtherItems = this.selectedOtherItem
      const selectedOtherItemCounts = this.selectedOtherItemCount
      const isPayTicketWithSportCard = this.isPayTicketWithSportCard
      const ticketSportCardDiscountPercentage = this.ticketSportCardDiscountPercentage
      const ticketSportCardDiscountFix = this.ticketSportCardDiscountFix
      const isSeasonBuy = this.isSeasonBuy
      const seasonPassPrice = this.seasonPassPrice
      let item
      let count
      // eslint-disable-next-line
      let isSumPriceRecalculateNeed = this.isSumPriceRecalculateNeed
      this.isSumPriceRecalculateNeed = false

      /* pass */
      for (const i in selectedPasses) {
        item = selectedPasses[i]
        count = selectedPassCounts[i]
        if (item && count) {
          sumPrice += item.price * count
        }
      }
      /* ./pass */

      /* other item */
      for (const i in selectedOtherItems) {
        item = selectedOtherItems[i]
        count = selectedOtherItemCounts[i]
        if (item && count) {
          sumPrice += item.price * count
        }
      }
      /* ./other item */

      /* ticket */
      if (this.ticket.isTicket) {
        console.log("ticket.........................");
        const ticketPriceHelper = (Number(this.curTicketPrice) * (Number(this.reservationLength) / this.curTicket.timeUnitMins))
        if (isPayTicketWithSportCard) {
          if (ticketSportCardDiscountPercentage) {
            // percentage discount
            sumPrice += ticketPriceHelper - ticketPriceHelper * ticketSportCardDiscountPercentage / 100
          } else if (ticketSportCardDiscountFix) {
            // fix discount
            sumPrice += ticketPriceHelper - (ticketSportCardDiscountFix * (Number(this.reservationLength) / this.curTicket.timeUnitMins))
          } else {
            // no chance
            sumPrice += ticketPriceHelper
          }
        } else {
          sumPrice += ticketPriceHelper
        }
      }
      /* ./ticket */

      /* ./season */
      if (isSeasonBuy) {
        sumPrice += Number(seasonPassPrice)
      }
      /* season */

      this.payAmount = sumPrice

      return sumPrice
    },
    curTicket () {
      const curGuestType = this.$store.state.guest.current.guestType
      const availableItems = this.$store.state.availableItems
      const ticketID = this.ticket.ID
      const isTicket = this.ticket.isTicket
      const isClub = Number(this.ticket.isClub)
      let ticket = {}
      //console.log("set ticket......");

      if (isTicket) {
        if (availableItems.hasOwnProperty(curGuestType)) {
          if (isClub) {
            // club ticket
            if (availableItems[curGuestType].hasOwnProperty('ticket_club')) {
              if (availableItems[curGuestType].ticket_club.hasOwnProperty(ticketID)) {
                ticket = availableItems[curGuestType].ticket_club[ticketID]
              }
            }
          } else {
            // normal ticket
            if (availableItems[curGuestType].hasOwnProperty('ticket')) {
              if (availableItems[curGuestType].ticket.hasOwnProperty(ticketID)) {
                ticket = availableItems[curGuestType].ticket[ticketID]
              }
            }
          }
        }
      }

      this.curTicketPrice = ticket.price
      return ticket
    },
    currentUserBalance () {
      return this.$store.state.guest.current.balance
    },
    getTicketSportCard () {
      const curTicket = this.curTicket
      if (curTicket.hasOwnProperty('sportCard')) {
        if (curTicket.sportCard.hasOwnProperty('sportCardPercentageDiscount') &&
            curTicket.sportCard.hasOwnProperty('sportCardFixDiscount')) {
          this.ticketSportCardDiscountPercentage = curTicket.sportCard.sportCardPercentageDiscount
          this.ticketSportCardDiscountFix = curTicket.sportCard.sportCardFixDiscount

          return curTicket.sportCard
        }
      }
      return null
    },
    currentGuest () {
      return this.$store.state.guest.current
    },
    getSeasonReservationHour () {
      const seasonResData = this.seasonReservationData
      if (seasonResData && seasonResData.hasOwnProperty('start')) {
        return moment(seasonResData.start).format('HH:mm')
      }
      return false
    },
    getSeasonReservationDayName () {
      const seasonResData = this.seasonReservationData
      if (seasonResData && seasonResData.hasOwnProperty('start')) {
        return moment(seasonResData.start).format('dddd')
      }
      return false
    },
    getSeason () {
      const seasons = this.$store.state.availableSeasons
      if (seasons && this.seasonReservationData && this.seasonReservationData.hasOwnProperty('reservationDate')) {
        const resDate = moment(this.seasonReservationData.reservationDate)
        for (const season of seasons) {
          if (resDate.isAfter(moment(season.canPurchaseFrom)) && resDate.isBefore(moment(season.canPurchaseTo))) {
            return season
          }
        }
      }
      return null
    },
    getSeasonPass () {
      const curGuest = this.currentGuest
      const availableItems = this.$store.state.availableItems
      // eslint-disable-next-line
      let isSeasonBuy = this.isSeasonBuy

      if (availableItems.hasOwnProperty(curGuest.guestType)) {
        if (availableItems[curGuest.guestType].hasOwnProperty('season_pass')) {
          const helperObj = availableItems[curGuest.guestType].season_pass
          const seasonPass = helperObj[Object.keys(helperObj)[0]]
          this.seasonPassPrice = Number(seasonPass.price)
          return seasonPass
        }
      }

      return null
    },
    getSeasonStart () {
      const season = this.getSeason
      if (season && season.hasOwnProperty('seasonStart')) {
        return moment(season.seasonStart).format('YYYY.MM.DD')
      }
      return null
    },
    getSeasonEnd () {
      const season = this.getSeason
      if (season && season.hasOwnProperty('seasonEnd')) {
        return moment(season.seasonEnd).format('YYYY.MM.DD')
      }
      return null
    },
    getSeasonPassName () {
      const seasonPass = this.getSeasonPass
      if (seasonPass && seasonPass.hasOwnProperty('name')) {
        return seasonPass.name
      }
      return null
    }
  },
  methods: {
    beforeModalOpen (event) {
      let someError = false
      if (event.hasOwnProperty('params')) {
        if (event.params.hasOwnProperty('isTicket')) {
          if (event.params.isTicket) {
            this.ticket.isTicket = event.params.isTicket
            this.ticket.timeTableID = event.params.timeTableID
            this.ticket.ID = event.params.ticketID
            this.reservationLength = event.params.length
            if (event.params.hasOwnProperty('isClub')) {
              this.ticket.isClub = event.params.isClub
            }
          } else {
            this.ticket.isTicket = event.params.isTicket
            this.ticket.timeTableID = null
            this.ticket.ID = null
            this.reservationLength = 0
          }
          someError = false
        } else {
          someError = true
        }

        if (event.params.hasOwnProperty('isPassBuy')) {
          if (event.params.isPassBuy) {
            this.isPassBuy = true
          }
        }

        if (event.params.hasOwnProperty('isOtherItemBuy')) {
          if (event.params.isOtherItemBuy) {
            this.isOtherItemBuy = true
          }
        }

        if (event.params.hasOwnProperty('isSeasonBuy')) {
          if (event.params.isSeasonBuy) {
            this.isSeasonBuy = true
            this.seasonReservationData = event.params.reservation
          }
        }
      } else {
        someError = true
      }

      if (someError) {
        this.ticket.isTicket = event.params.isTicket
        this.ticket.timeTableID = null
        this.ticket.ID = null
      }
    },
    modalClosed () {
      // if modal is closed, reset all data to default value
      this.purchasePassKeys = []
      this.purchaseOtherKeys = []
      this.purchasePassCount = 0
      this.purchaseOtherCount = 0
      this.selectedPass = {}
      this.selectedPassCount = {}
      this.selectedOtherItem = {}
      this.selectedOtherItemCount = {}
      this.ticket = {
        ID: null,
        isTicket: false,
        timeTableID: null,
        isClub: false
      }
      this.reservationLength = 0
      this.curTicketPrice = 0
      this.isPassBuy = false
      this.isOtherItemBuy = false
      this.isSeasonBuy = false
      this.isPayTicketWithSportCard = false
      this.ticketSportCardDiscountPercentage = null
      this.ticketSportCardDiscountFix = null
      this.seasonReservationData = null
      this.seasonPassPrice = 0

      console.log('--- UserPurchaseModal reset')
    },
    hideModal () {
      this.$modal.hide('userPurchaseModal')
    },
    addPurchasePass () {
      if (this.purchasePassKeys.length === 0) {
        this.purchasePassKeys.push(0)
        this.selectedPassCount[0] = 1
      } else {
        const lastElem = this.purchasePassKeys[this.purchasePassKeys.length - 1]
        this.purchasePassKeys.push((lastElem + 1))
        this.selectedPassCount[lastElem + 1] = 1
      }
    },
    removePurchasePass (deletableKey) {
      const index = this.purchasePassKeys.indexOf(deletableKey)
      if (index > -1) {
        this.purchasePassKeys.splice(index, 1)
      }
      delete this.selectedPass[deletableKey]
      delete this.selectedPassCount[deletableKey]
      this.sumPriceRecalculateStart()
    },
    addPurchaseOtherItem () {
      if (this.purchaseOtherKeys.length === 0) {
        this.purchaseOtherKeys.push(0)
        this.selectedOtherItemCount[0] = 1
      } else {
        const lastElem = this.purchaseOtherKeys[this.purchaseOtherKeys.length - 1]
        this.purchaseOtherKeys.push((lastElem + 1))
        this.selectedOtherItemCount[lastElem + 1] = 1
      }
    },
    removePurchaseOtherItem (deletableKey) {
      const index = this.purchaseOtherKeys.indexOf(deletableKey)
      if (index > -1) {
        this.purchaseOtherKeys.splice(index, 1)
      }
      delete this.selectedOtherItem[deletableKey]
      delete this.selectedOtherItemCount[deletableKey]
      this.sumPriceRecalculateStart()
    },
    purchase (isLocalBankCardPayment = false) {
      /*
    *  usedItems:
    *      {integer} boughtItemIndex,
    *      {integer} timeTableID
    */
      const selectedPasses = this.selectedPass
      const selectedPassCounts = this.selectedPassCount
      const selectedOtherItems = this.selectedOtherItem
      const selectedOtherItemCounts = this.selectedOtherItemCount
      const originalItems = this.$store.state.availableItems
      const curGuestType = this.$store.state.guest.current.guestType
      const purchaseData = {
        userID: this.$store.state.guest.current.ID,
        invokerID: this.$store.state.admin.ID,
        boughtItems: [],
        paymentAmount: document.getElementById('userPayAmount').value,
        isLocalBankCardPayment: isLocalBankCardPayment
      }

      for (const i in selectedPasses) {
        if (originalItems.hasOwnProperty(curGuestType)) {
          if (originalItems[curGuestType].hasOwnProperty('pass')) {
            if (originalItems[curGuestType].pass.hasOwnProperty(selectedPasses[i].ID)) {
              purchaseData.boughtItems.push(
                {
                  ID: selectedPasses[i].ID,
                  price: selectedPasses[i].price,
                  originalPrice: originalItems[curGuestType].pass[selectedPasses[i].ID].price,
                  quantity: selectedPassCounts[i],
                  validDay: selectedPasses[i].validDay,
                  occasion: selectedPasses[i].occasion,
                  itemType: 'pass'
                }
              )
            }
          }
        }
      }

      for (const i in selectedOtherItems) {
        if (originalItems.hasOwnProperty(curGuestType)) {
          if (originalItems[curGuestType].hasOwnProperty('other')) {
            if (originalItems[curGuestType].other.hasOwnProperty(selectedOtherItems[i].ID)) {
              purchaseData.boughtItems.push(
                {
                  ID: selectedOtherItems[i].ID,
                  price: selectedOtherItems[i].price,
                  originalPrice: originalItems[curGuestType].other[selectedOtherItems[i].ID].price,
                  quantity: selectedOtherItemCounts[i],
                  itemType: 'other'
                }
              )
            }
          }
        }
      }
      if (this.ticket.isTicket) {
        purchaseData.ticket = {
          ID: this.ticket.ID,
          timeTableID: this.ticket.timeTableID,
          price: this.curTicketPrice,
          originalPrice: this.curTicket.price,
          duration: this.reservationLength,
          isPayedWithSportCard: this.isPayTicketWithSportCard
        }
        if (this.isPayTicketWithSportCard) {
          const ticketPriceHelper = Number(this.curTicketPrice)
          if (this.ticketSportCardDiscountPercentage) {
            // percentage discount
            purchaseData.ticket.price = ticketPriceHelper - ticketPriceHelper * this.ticketSportCardDiscountPercentage / 100
          } else if (this.ticketSportCardDiscountFix) {
            // fix discount
            purchaseData.ticket.price = ticketPriceHelper - this.ticketSportCardDiscountFix
          }
        }
      }

      if (this.isSeasonBuy) {
        purchaseData.boughtItems.push(
          {
            ID: this.getSeasonPass.ID,
            price: this.seasonPassPrice,
            originalPrice: this.getSeasonPass.price,
            quantity: 1,
            validDay: null,
            occasion: 0,
            itemType: 'season_pass'
          }
        )
        purchaseData.seasonData = {
          seasonBuyData: {
            salePrice: this.seasonPassPrice,
            seasonPass: this.getSeasonPass
          },
          seasonReservationData: this.seasonReservationData,
          season: this.getSeason
        }
      }

      console.log('--- new purchase data: ')
      console.log(purchaseData)

      this.$http({
        url: this.$store.state.url.buy,
        method: 'POST',
        params: { userID: getCookie('userID'), token: getCookie('token') },
        body: JSON.stringify({ data: purchaseData })
      }).then(res => {
        console.log('--- success buy')
        notify.success(res.body.msg)
      }, error => {
        errorHandle.httpError(error)
      }).finally(() => {
        this.$modal.hide('userPurchaseModal')
      })
    },
    addDebitToPayAmount () {
      this.payAmount -= this.currentUserBalance
    },
    getGuestTypeName: util.getGuestTypeName,
    sumPriceRecalculateStart () {
      this.isSumPriceRecalculateNeed = true
    }
  }
}
</script>

<style scoped>
  .callout {
    padding: 0.5rem;
  }

  .negativeBalance {
    color: #cc4b37;
  }
</style>
