import { getCookie, setCookie, deleteCookie } from './cookie'
import config from '../config'

function isLoggedIn () {
  const tokenCookieName = config.tokenCookieName
  if (getCookie(tokenCookieName).length > 0) {
    return true
  } else {
    return false
  }
}

function authRedirect (to, from, next) {
  if (isLoggedIn()) {
    // he's logged in
    if (to.name === 'Login') {
      // cant open login page if he's already logged in
      next('/')
    } else {
      next()
    }
  } else {
    // if not logged in redirect to login page
    if (to.name === 'Maintenance') {
      next()
    } else {
      if (to.name !== 'Login') {
        next('/login')
      } else {
        next()
      }
    }
  }
}

function logMeIn (userID, token) {
  setCookie('userID', userID, 1)
  setCookie('token', token, 1)
  location.reload(true)
}

function logOut () {
  deleteCookie('userID')
  deleteCookie('token')
  location.reload(true)
}

export { logMeIn, authRedirect, logOut, isLoggedIn }
